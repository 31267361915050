import LoaderComponent from './components/LoaderComponent/LoaderComponent.lazy';
import { Toaster } from "react-hot-toast";
import './styles.scss';
import { useEffect } from 'react';
import { openSocket } from './service/socket.service';
import { RootState } from './Redux/store';
import { useSelector } from 'react-redux';
import { NotificationToaster } from './components/toaster/NotificationToaster.component';
import useClarity from './Clarity';

const App = () => {
  useClarity();
  const { toaster } = useSelector((state: RootState) => state.toaster || {});

  useEffect(() => {
    // Open the socket connection only once
    openSocket();

    // Cleanup: Disconnect the socket when the component unmounts
    // return () => {
    //   if (socket && socket.connected) {
    //     socket.disconnect();
    //   }
    // };
  }, []); // Empty array ensures this runs only on mount and unmount
  return (
    <>
      <LoaderComponent/>
      {toaster?.status && (
        <NotificationToaster status={toaster.status} message={toaster.message} type={toaster.type} duration={toaster.duration} />
      )}
      {/* <Toaster position="top-right"/> */}
    </>
  )
}

export default App;
