import Tooltip from 'react-bootstrap/Tooltip';
import { DoctorCountryCurrencyInfo, PlansData } from '../types';

export function getCurrentTimezone() {
    let time = new Date().getTimezoneOffset();
    let hours = Math.abs(Math.trunc(time / 60));
    let minutes = ("0" + time % 60).slice(-2);
    let user_timezone = '';
    if (time > 0) {
        user_timezone = "-" + hours + minutes;
    } else {
        user_timezone = "+" + hours + minutes;
    }
    return user_timezone;
}

export function countryWiseRegex() {
    return {
        US: {
          regex: /(\d{3})(\d{3})(\d{4})/g,
          group: '$1-$2-$3'
        },
        CA: {
          regex: /(\d{3})(\d{3})(\d{4})/g,
          group: '$1-$2-$3'
        },
        GB: {
          regex:  /(\d{4})(\d{5})/g,
          group: '$1 $2'
        },
        AU: {
          regex: /(\d{1})(\d{4})(\d{4})/g,
          group: '$1 $2 $3'
        },
        NZ: {
          regex: /(\d{1})(\d{4})(\d{4})/g,
          group: '$1 $2 $3'
        },
        DE: {
          regex: /(\d{4})(\d{7})/g,
          group: '$1 $2'
        },
        IT: {
          regex: /(\d{3})(\d{7})/g,
          group: '$1 $2'
        },
        FR: {
          regex: /(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/g,
          group: '$1 $2 $3 $4 $5'
        },
        ES: {
          regex: /(\d{3})(\d{6})/g,
          group: '$1 $2'
        },
        PL: {
          regex: /(\d{3})(\d{3})(\d{3})/g,
          group: '$1 $2 $3'
        },
        JP: {
          regex: /(\d{2})(\d{4})(\d{4})/g,
          group: '$1 $2 $3'
        },
    }
}

export const plansFinancingSection = [
  {
    step: 1,
    stepEnum: 'PLANS'
  },
  {
    step: 2,
    stepEnum: 'CUSTOMER'
  },
  {
    step: 3,
    stepEnum: 'RECURRING'
  },
  {
    step: 4,
    stepEnum: 'TOTALPAYMENT'
  }
]

export function zipObjectCovert(item: any) {
  const desire_object: any = {};
  let temp: any
  desire_object.place_id = item?.place_id
  desire_object.item = item
  temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_code') || inner.types.includes('plus_code'));
  temp = temp && temp.long_name;
  desire_object.zip = temp;
  desire_object.zipCode = temp;

  temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('locality'));
  temp = temp || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_2'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_town'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('neighborhood'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_3')));

  temp = temp && temp.long_name;
  desire_object.city = temp;

  temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_1'));
  desire_object.state_name = temp && temp.long_name;
  desire_object.state_code = temp && temp.short_name;
  desire_object.State = temp && temp.short_name;


  temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('country'));
  desire_object.country_name = temp && temp.long_name;
  desire_object.country_code = temp && temp.short_name;

  if (item.geometry && item.geometry.location) {
      desire_object.latitude = item.geometry.location.lat();
      desire_object.longitude = item.geometry.location.lng();
  }
  return desire_object
}

export const defferedMonths = 12;

export const contractTypes = {
  noFee: 2,
  ihf: 3,
  ez_with_deffered: 6
};

export async function base64ToBlob(base64String: string) {
  const dataURI = base64String;

  if(!dataURI){ return null; }

  let type = 'image/jpeg';
  if (dataURI.includes('application/pdf')) {
    type = 'application/pdf';
  }
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], {
    type
  });
  return blob;
}

function extractFirstText(str: string) {
  const matches = str.match(/"(.*?)"/);
  return matches
    ? matches[1]
    : str;
}

export const downloadType = {
  DOWNLOAD: 'DOWNLOAD',
  PRINT: 'PRINT'
}

// this is user for existing cards modal in for cases like
// 1. Add new card
// 2. Close modal
// 3. Submit selected card
// will store the value here and then reset
export const closeExisingPaymentSourceModal = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  SELECTED_CARD: 'SELECTED_CARD',
  ADD_CARD: "ADD_CARD",
  ADD_BANK: 'ADD_BANK'
}

export const downloadPdf = async (response: any, type = downloadType.DOWNLOAD) => {
  try {
    const headerstext: any = response?.headers.get('Content-Disposition');
    const fileName = extractFirstText(headerstext);
    const data = await response.blob();
    var blob = new Blob([data], { type: 'application/pdf' });
    var urlwindow = window.URL.createObjectURL(blob);
    if (type === downloadType.DOWNLOAD){
      var anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = urlwindow;
      anchor.click();
    } else {
      var pwa = window.open(urlwindow);
      if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
        throw new Error("Please disable your browser's pop-up blocker and try again.");
      }
    }    
  } catch (error) {
    console.log(error);
  }
}

export function TooltipFn(text: React.ReactNode) {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    renderTooltip
  )
} 

export interface FinanceOnBusinessState {
  contract_patient_token: string;
}


export const renderHtMLForTotalPayable = (item: PlansData, doctor_country_currency_info: DoctorCountryCurrencyInfo, downpayment: number | string, charge_first_recuring_for_debt_recovery: boolean = false) => {
  return item.payment_reserve_on_pay_new_ten === 1 &&
    item.contract_type === 3 ? (
    <p className="align-left pt-2">
     It includes first recurring payment and enrollment fee.
    </p>
  ) : item.payment_reserve_on_pay_new_ten === 0 && item.contract_type === 3 ? (
    <p className="align-left pt-2">It includes Enrolment fees.</p>
  ) : ( item.contract_type === 2 ? 
    (
      <p className='align-left pt-2'>It includes upfront payment and enrollment fee</p>
    ) :
    <div>
      <div style={{"padding": "15px 15px 15px 16px"}}>
        {((Number(downpayment || 0) > 0) || charge_first_recuring_for_debt_recovery) && 
        <>
          <div className="text-start fs-14 w-600" style={{"color": "#ABABAB"}}>{charge_first_recuring_for_debt_recovery ? 'First Recurring Amount' : 'Upfront amount'}</div>
          <h4 className="text-start fs-14 w-700" style={{"borderBottom": "solid 1px #ccc", "paddingBottom": "10px"}}> 
            { doctor_country_currency_info?.currency[0]?.display_logo } { downpayment || (charge_first_recuring_for_debt_recovery && item?.transaction_fee ? (Number(item?.to_pay_today) - Number(item?.transaction_fee)) : 0) || 0 } </h4>
        </>
          }
        <div className="text-start fs-14 w-600" style={{"color": "#ABABAB"}}>
          Transaction fees
        </div>
        <h4 className="text-start fs-14 w-700" style={{"paddingBottom": "10px"}}> {doctor_country_currency_info?.currency[0]?.display_logo} {item.downpayment_transaction_fee ? formatNumber(item.downpayment_transaction_fee) :  formatNumber(item.transaction_fee) || 0 } </h4>

        {item && Number(item.enrollment_fee) > 0 && (
          <>
            <div
              className="text-start fs-14 w-600"
              style={{ color: "#ABABAB" }}
            >
              Enrollment Fee
            </div>
            <h4 className="text-start fs-14 w-700"> {doctor_country_currency_info?.currency[0]?.display_logo} { formatNumber(item.enrollment_fee) } </h4>
          </>
        )}
      </div>
    </div>
  );
}

export const getItem = (key: string) => {
  const data = localStorage.getItem('financemutual_request') ? JSON.parse(localStorage.getItem('financemutual_request') || '') : '';
  if (data && Object.keys(data).length) {
    if (data[key]) {
      return JSON.parse(data[key]);
    }
  }
  return null;
}

export const renderHtMLForInterestTooltip = (item: PlansData) => {
  return (
    <div>
      <div style={{"padding": "15px 20px 15px 16px"}}>
        <h4 className="text-start fs-14 fw-700 mb-1"> 
          {item.recurring_amount} / month
        </h4>
        <div className="text-start fs-14 fw-600 mb-1" style={{"color": "#ABABAB"}}>First {defferedMonths} months</div>
        <div className="text-start fs-14 fw-700" style={{"color": "#1dc779", 'paddingBottom': '12px', borderBottom: 'solid 1px #ccc'}}>
          No Interest
        </div>
        <h4 className="text-start fs-14 w-700 mb-1" style={{paddingTop: '12px'}}> 
          {item.next_recurring_amount} / month
        </h4>
        <div className="text-start fs-14 fw-600 mb-1" style={{"color": "#ABABAB"}}>Remaining {item.month - defferedMonths} months</div>
        <div className="text-start fs-14 fw-600" style={{"color": "#1dc779"}}>
          Interest: {item.interest_rate_to_show}%
        </div>
      </div>
    </div>
  )
}



export const fetchAddress = ( item: any ) => {
  const desire_object: any = {};
    let temp: any
    desire_object.place_id = item.place_id
    desire_object.item = item
    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_code') || inner.types.includes('plus_code'));
    temp = temp && temp.long_name;
    desire_object.zip = temp;
    desire_object.zipCode = temp;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('locality'));
    temp = temp || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_2'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_town'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('neighborhood'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_3'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('sublocality'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('sublocality_level_1')));

    temp = temp && temp.long_name;
    desire_object.city = temp;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_1'));
    desire_object.state_name = temp && temp.long_name;
    desire_object.state_code = temp && temp.short_name;
    desire_object.State = temp && temp.short_name;


    // temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('country'));
    // desire_object.country_name = temp && temp.long_name;
    // desire_object.country_code = temp && temp.short_name;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('route'))
    desire_object.address_line_1 = temp && temp.long_name;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('street_number'));
    desire_object.address_line_1 += temp && temp.long_name;

    if (item.geometry && item.geometry.location) {
      desire_object.latitude = item.geometry.location.lat();
      desire_object.longitude = item.geometry.location.lng();
    }
    return desire_object
};

export const getGoogleAPIKey = () => {
  if (window.location.href.includes('.financemutual.com')) {
      return 'AIzaSyAa0GBtCtHP69nQ3EhYc69G2xyXXXAQCYE';
  } else {
      return 'AIzaSyAB3iHn_Dc0nEjS7q53tfCBWVm1_Dj1fdI'
  }
}
export const PAYMENTTYPES = {
  PAYOFF: 'PAYOFF'
}
export const setItem = (key: string, value: any) => {
  if (localStorage.getItem('financemutual_request')) {
    const data = JSON.parse(localStorage.getItem('financemutual_request') || '');
    data[key] = value;
    localStorage.setItem('financemutual_request', JSON.stringify(data));
  } else {
    const financemutual_request = {
      [key]: value
    };
    localStorage.setItem('financemutual_request', JSON.stringify(financemutual_request));
  }
}

export const responseType = {
  ADDNEW: 'ADDNEW',
  CLOSEMODAL: 'CLOSEMODAL',
  KEEPSAME: 'KEEPSAME'
}


export const formatPhoneNumber = (number: any) =>  {

  if(!number)return false;

  const areaCode = number.substring(0, 3);
  const centralOfficeCode = number.substring(3, 6);
  const lineNumber = number.substring(6);

  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
}

export const formatNumber = (value: number) => {
  // console.log('valuevalue', value)
  const formatter = new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: value < 10 ? 1 : 2,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // console.log(formatter.format(value));
  return formatter.format(value);
}

export const getDateSuffix = (date: any) => {
  let suffix = 'st';
  if (date) {
      suffix = (date == 11 || date == 12 || date == 13) ? 'th' : String(date).slice(-1) == '1' ? "st" : String(date).slice(-1) == '2' ? "nd" : String(date).slice(-1) == '3' ? "rd" : 'th';
  }
  return suffix;
}

export const getCurrentDate = () => {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const year = today.getFullYear();

  return `${month}/${day}/${year}`;
}

export const ssncustomValidator = (value: string): boolean => {
  // Your validation logic goes here
  const invalid_ssn = ['002281852', '042103580', '062360749', '078051120', '095073645', '128036045', '135016629', '141186941', '165167999', '165187999', '165207999', '212099999', '165227999', '306302348', '165247999', '189092294', '308125070', '212097694', '549241889', '468288779', '123456789', '012345678'];

  if (value && (value.trim().length != 9 || value.slice(5, 9) == "0000" || value.slice(3, 5) == "00" || /^(.)\1+$/.test(value) || /[a-zA-Z]/.test(value) || value.slice(0, 3) == "000" || value.slice(0, 3) == "666" || invalid_ssn.indexOf(value) >= 0 || value == "9876543210" || parseInt(value) >= 987654320 && parseInt(value) <= 987654329)){
    return true;
  }
  return false;
}