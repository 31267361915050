import { FC, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../Redux/store';
import { PaymentMethodType } from '../../../types';
import httpApiCall, { apiMethodType } from '../../../service/api.service';
import { setLoading } from '../../../Redux/slices/loader.slice';
import { downloadPdf, formatNumber, getItem } from '../../../service/shared.service';
import CopyRightFooter from '../../LayoutComponent/copyrightfooter/CopyRightFooter';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

interface PaymentSuccessfullProps {
   payment_source_type: any
}

const PaymentSuccessfull: FC<PaymentSuccessfullProps> = ({ payment_source_type }) => {

   const { doctor_country_currency_info, split_request_data } = useSelector((state: RootState) => state.paymentDetails);
   
   let currency =  (doctor_country_currency_info && doctor_country_currency_info.currency && doctor_country_currency_info.currency[0]) ?  doctor_country_currency_info.currency[0].display_logo : '$';
   const dispatch = useDispatch();
   const [email, setEmail] = useState('');
   const [error, setError] = useState(true);
   const [focused, setFocused] = useState(false);
   const [showSendReceiptPage, setshowSendReceiptPage] = useState(false);
   const { token } = useParams<{ token: string }>();
   const navigate = useNavigate();
   const [to_pay_today, set_to_pay_today] = useState<any>();

   useEffect(() => {
      if (split_request_data && Object.keys(split_request_data).length && token !== split_request_data['contract_patient_token']) {
         navigate('/invalid');
      }

      if (getItem('pay_in_full') && JSON.parse(getItem('pay_in_full'))) {
         const amount = Number(split_request_data?.treatment_amount) + Number(split_request_data?.paid_full_transaction_fee || 0);
         set_to_pay_today(amount.toFixed(2));
      } else {
         set_to_pay_today(split_request_data?.treatment_amount);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const emailSchema = yup.string()
   .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "Invalid email format")
   .email("Invalid email format")
   .required("Email is required");

   const downloadReceipt = async () => {
      const params = {
         body: {
            contract_patient_token: token,
            show_reason_to_customer: 0
         },
         api: 'proposal/download_receipt',
         type: 'GET' as apiMethodType
      };
      dispatch(setLoading(true));
      const response = await httpApiCall(params);
      dispatch(setLoading(false));
      if (!response.is_error) {
         downloadPdf(response.link)
         window.open(response.link, '_blank');
      }
   }

   const sendReceipt = async () => {
      if(error){
         setFocused(true)
         return
      }
     const params = {
       body: {
         split_request_id: split_request_data?.split_req_id,
         email: email || split_request_data?.patient_email,
       },
       api: "proposal/send_download_receipt_link",
       type: "POST" as apiMethodType,
     };
     dispatch(setLoading(true));
     const response = await httpApiCall(params);
     dispatch(setLoading(false));
     if (!response.is_error) {
       setshowSendReceiptPage(true);
     }
   };
   const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setEmail(value);

      try {
         await emailSchema.validate(value);
         setError(false);
      } catch (validationError: any) {
         setError(true);
      }
   }

   const showerror = () => {
      return <>{error && <p style={{ color: "red", textAlign: 'left', margin: 0 }}>{'please enter a valid email'}</p>} </>
   }

   return (<>
   <div className="h-100 d-flex flex-column justify-content-between">
      <Container className='h-100'>
         <div className='payment-wrapper d-flex flex-column justify-content-center align-items-center mxw-420 mx-auto mt-5 text-center h-100'>
            {!showSendReceiptPage && <>
            <div className=''>
            {(payment_source_type === PaymentMethodType.CARD || payment_source_type === PaymentMethodType.DEBIT) && 
               <img src='/assets/images/bigGreentick.svg' alt='' />
            }
            {payment_source_type === PaymentMethodType.BANK && 
               <img src='/assets/images/initiated.png' alt='' />
            }
            </div>
            {(payment_source_type === PaymentMethodType.CARD || payment_source_type === PaymentMethodType.DEBIT) && to_pay_today &&
               <>
                  <h3 className="mt-2 fs-24 fw-800 m-0 mb-3 black-19"> Payment of {currency}{formatNumber(to_pay_today)} is successfully Done! </h3>
                  <div style={{ color: '#42474D', fontSize: '18px', lineHeight: '25px' }}>
                     <p className='fs-16 fw-500 m-0 black-65'>Thank you for settling your account in full. We’ve received your payment. Your account is now marked as cleared.</p>
                  </div>

                  {split_request_data && split_request_data.patient_email && 
                     <div className='mt-4'>
                        
                           <label className='fs-16 w-100 grey-102 fs-14 fw-500' style={{ color: '#344054', textAlign: 'left'}}>Send receipt by email</label>
                           <input onChange={handleChange} className="input_field w-100 mt-2" placeholder='Enter Email' onFocus={() => setFocused(true)}/>
                           {focused && error && <p style={{ color: "red", textAlign: 'left', margin: 0 }}>{'Please enter a valid email'}</p>}
                           <button className="btn btn-secondary fs-18 fw-500 w-100 mt-3" 
                           onClick={() => sendReceipt()} 
                           >Send Receipt</button>                           
                       
                     </div>
                  }
                  <div className='mt-3 fs-16 fw-700 cursor' style={{ color: 'rgba(2, 103, 255, 1)' }} onClick={() => downloadReceipt()}>Download Receipt</div>

               </>}
            {payment_source_type === PaymentMethodType.BANK && split_request_data?.treatment_amount && <div>
               <>
                  <h3 className="mt-2"> Payment of {currency}{split_request_data?.treatment_amount} is initiated! </h3>
                  <div style={{ color: '#42474D', fontSize: '18px', lineHeight: '25px' }}>
                     <div>
                        Your payment has been successfully initiated. You will receive a confirmation once the transaction is processed within 2-5 business days.
                     </div>
                  </div>
               </>
            </div>}
            {(!split_request_data || !split_request_data?.treatment_amount) &&
               <>
                  <h3 className=""> Your payment has already been processed for this account </h3>
               </>
            }
            </>}
            {showSendReceiptPage && <> 
              <div className=''>
              <img src='/assets/images/reciept-sent.svg' className='mt-5' alt='' />
            <h4 className='fs-24 fw-800 m-0 black-19'>
            Receipt sent on your email
            </h4>
            <p className='fs-16 fw-500 black-65 m-0'>
            Your receipt has been successfully delivered to your registered SMS or email address for your records.
            </p>
              </div>
            </>}
         </div>
      </Container>
      <CopyRightFooter />
      </div>
   </>)
}

export default PaymentSuccessfull;
