import { useEffect } from "react";

declare global {
  interface Window {
    clarity?: ((...args: any[]) => void) & { q?: any[] };
  }
}

const useClarity = () => {
  useEffect(() => {
    if (window.location.href.includes('https://request.financemutual')) {
      if (!window.clarity) {
        const clarityFunction = ((...args: any[]) => {
          (window.clarity!.q = window.clarity!.q || []).push(args);
        }) as ((...args: any[]) => void) & { q?: any[] };
  
        clarityFunction.q = [];
        window.clarity = clarityFunction;
      }
  
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://www.clarity.ms/tag/qjtnzllmks"; // Replace with your actual Clarity ID
      document.head.appendChild(script);
  
      return () => {
        document.head.removeChild(script);
      };
    }
    // Define clarity as a function with a queue property
  }, []);
};

export default useClarity;
