import React, { useEffect, useState } from 'react';
import httpApiCall from '../../service/api.service';
import { showToaster } from '../../Redux/slices/toaster.slice';
import { ToasterType } from '../../types';
import { AppDispatch } from '../../Redux/store';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentDate } from '../../service/shared.service';

export const TermsTemplatePage = () => {
    const [loading, setLoading] = useState(false);
    const [templateContent, setTemplateContent] = useState('');
    const dispatch = useDispatch<AppDispatch>();
      const { token, start_date } = useParams<{ token: string, start_date: string }>();
    
    const getTilaClosureForm = async () => {
        setLoading(true);

        if(!token){
            dispatch(showToaster({ message: "Something went wrong", type: ToasterType.Danger, status: true }));   
            return false;
        }

        const response = await httpApiCall({
            type: "GET",
            body: {
              token,
              start_date,
              agreement_date: getCurrentDate()
            },
            api: `proposal/get_closure_html`
        });

        if (!response || (response.is_error && response.is_error === 1)) {

            dispatch(showToaster({ message: "Something went wrong!", type: ToasterType.Danger, status: true }));
        } 
        setLoading(false);
        setTemplateContent(response.template_content);
    }

    useEffect(() => {
        
        getTilaClosureForm();        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
        {loading && (
         <div style={{visibility: loading ? 'visible' : 'hidden', zIndex: 1060}}
         className={`overlay position-fixed p-3 h-100 w-100 d-flex justify-content-center align-items-center `}>
         <div
           className="fm-spinner p-3 d-flex justify-content-center align-items-center rounded-circle"
         >
           <img src="/assets/images/logo-icon.svg" alt="" className="h-100" />
         </div>
       </div>)
        } 

    <div
      dangerouslySetInnerHTML={{ __html: templateContent }}
      style={{ width: '100vw', height: '100vh' }}
    ></div>
      </>
    
  );
};
