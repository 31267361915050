
const CopyRightFooter = () => {
  return (
    <>
    <div className="copyright-footer hw-65 w-100  border-top">
      <div className="container-xl hw-65 w-100 d-flex flex-column-reverse flex-sm-row px-4 py-2  align-items-center justify-content-between ">
      <p className="copyright-text m-0 fs-14 fw-14 text-center">
        Copyright © 2025 Finance Mutual. All Rights Reserved.
      </p>
      <div className="text-center text-sm-end">
          <p className='fs-12 m-0 text-center'>
            <img src="/assets/images/fm_logo_footer.svg" alt="" className='hw-24 rounded mx-2 d-sm-none' /> <br className='d-sm-none' />
             Powered by <br className='d-block d-sm-none' />
              <img src="/assets/images/fm_logo_footer.svg" alt="" className='hw-24 rounded mx-2 d-none d-sm-inline-block' />
          <strong className='footer-text'>Finance Mutual</strong></p>
        </div>
      </div>
    </div>
    </>
  );
};

export default CopyRightFooter;
