export const formatDate = (date: any) => {
  const dates = new Date(date); // Parse the date string
  const year = dates.getFullYear(); // Get the year (YYYY)
  let month = (dates.getMonth() + 1).toString().padStart(2, '0'); // Get the month (MM)
  let day = dates.getDate().toString().padStart(2, '0'); // Get the day (DD)

  return `${year}-${month}-${day}`;
}

export const formatDateNew = (date: string | Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  return new Date(date).toLocaleDateString('en-US', options);
};

