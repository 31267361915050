import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import "./CustomerDetails.scss";
import { setLoading } from "../../../Redux/slices/loader.slice";
import { saveInfoData, updatePatients } from "../../../Redux/slices/payment.slice";
import { RootState, AppDispatch } from "../../../Redux/store";
import httpApiCall from "../../../service/api.service";
import { base64ToBlob, fetchAddress, getGoogleAPIKey, getItem, ssncustomValidator } from "../../../service/shared.service";
// import toastrSer from "../../../service/toast.service";
import ContinueFooter from "../../LayoutComponent/continueFooter/ContinueFooter";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import CopyRightFooter from "../../LayoutComponent/copyrightfooter/CopyRightFooter";
import { showToaster } from "../../../Redux/slices/toaster.slice";
import { ToasterType } from "../../../types";

interface CustomerDetailsProps {
  disputeData?: any;
}

const CustomerDetails: FC<CustomerDetailsProps> = ({ disputeData }) => {
  const { patients, split_request_data, info } = useSelector(
    (state: RootState) => state.paymentDetails || {}
  );
  const dispatch = useDispatch<AppDispatch>();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  // const toastr = new toastrSer(true);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [fileData, setFileData] = useState<any>(null);
  const [docId, setDocID] = useState<string>("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const dobKeys = {
  //   month: "month",
  //   day: "day",
  //   year: "year",
  // };

  useEffect(() => {
    if (
      !split_request_data ||
      (split_request_data && !split_request_data.treatment_amount)
    ) {
      if (
        (getItem("partial_amount") && Number(getItem("partial_amount"))) ||
        getItem("create_plan")
      ) {
        navigate("/debt-recovery/recovery-plans/" + token);
      } else {
        navigate("/debt-recovery/" + token);
      }
    }
    if (patients && patients.date_of_birth) {
      const dob = patients.date_of_birth.toString().split("-");
      setValue("month", dob[0]);
      setValue("day", dob[1]);
      setValue("year", dob[2]);
    }
    if (patients && patients.dl_doc) {
      setDocID(patients?.dl_id);
      setPreviewImage(patients?.dl_doc);
    }

    const fields = [
      'patient_address',
      'dob',
      'patient_mobile',
      'address_line_2',
      'city'
    ];

    fields.forEach((field: string) => {
      let value = info[field];
      if (field === 'dob') {
        value = patients?.date_of_birth || split_request_data?.date_of_birth;
        if (value && value.includes('-')) {
          value = (value + '').replaceAll('-', '/');
        }
      } else if (field === 'address_line_2') {
        value = patients?.patient_address_2 || split_request_data?.patient_address_2;
      } else if (field === 'city') {
        value = patients?.city || split_request_data?.city;
      } else {
        if (patients && (patients as Record<string, any>)[field]) {
          value = (patients as Record<string, any>)[field];
        } else if (split_request_data && (split_request_data as Record<string, any>)[field]) {
          value = (split_request_data as Record<string, any>)[field];
        }
      }
      setValue(field, value || "");
    });
    // if(info?.patient_address){

    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  const validationSchema = Yup.object().shape({
    patient_image: Yup.string().nullable().notRequired(),
    patient_first_name: Yup.string().required("First name is required"),
    patient_last_name: Yup.string().required("Last name is required"),
    dob: Yup.string()
      .required("DOB is required")
      .test("valid-format", "Invalid date format. Use MM/DD/YYYY.", (value) => {
        if (!value) return false;
        const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
        return dateRegex.test(value); // Check the format
      })
      .test("valid-date", "Invalid date.", (value) => {
        if (!value) return false;
        const [month, day, year] = value.split("/").map(Number);

        // Check if the date is logically valid
        if (month < 1 || month > 12 || day < 1 || day > 31) return false;
        const daysInMonth = new Date(year, month, 0).getDate();
        if (day > daysInMonth) return false;

        return true;
      })
      .test("reasonable-year", "Invalid DOB", (value) => {
        if (!value) return false;
        const [_, __, year] = value.split("/").map(Number);
        const currentYear = new Date().getFullYear();
        return year >= 1900 && year <= currentYear;
      })
      .test("min-age", "You must be at least 18 years old.", (value) => {
        if (!value) return false;
        const [month, day, year] = value.split("/").map(Number);
        const birthDate = new Date(year, month - 1, day);
        const ageDiffMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDiffMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age >= 18;
      }),
    // month: Yup.string().required("Month is required"),
    // day: Yup.string().required("Day is required"),
    // year: Yup.string().required("Year is required"),
    patient_address: Yup.string().required("Address is required"),
    address_line_2: Yup.string().nullable().notRequired(), // Optional
    zip: Yup.string().required("ZIP is required"),
    city: Yup.string().required("City is required"),
    state_name: Yup.string().required("State is required"),
    patient_mobile: Yup.string().required("Phone number is required"),
    patient_ssn: Yup.string().required("SSN is required"),
    patient_email: Yup.string().required("Email is required").email("Invalid email format"),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      patient_first_name: patients?.patient_first_name || split_request_data?.patient_first_name || "",
      patient_last_name: patients?.patient_last_name || split_request_data?.patient_last_name || "",
      // dob: patients?.date_of_birth || split_request_data?.date_of_birth || "",
      patient_address: patients?.patient_address || split_request_data?.patient_address || "",
      address_line_2: patients?.patient_address_2 || split_request_data?.patient_address_2 || "",
      zip: patients?.patient_zip || split_request_data?.patient_zip || "",
      city: patients?.city || split_request_data?.city || "",
      state_name: patients?.state_name || split_request_data?.patient_state || "",
      patient_mobile: patients?.patient_mobile || split_request_data?.patient_mobile || "",
      patient_ssn: patients?.patient_ssn || split_request_data?.patient_ssn || "",
      patient_email: patients?.patient_email || split_request_data?.patient_email || "",
    },
  });

  const onSubmit = async (data: any) => {
    let docUrl: any = "";
    if (previewImage && !docId) {
      dispatch(setLoading(true));
      const blob = await base64ToBlob(previewImage);
      const uploadResp = await httpApiCall({
        type: "form",
        api: "debt/upload_proof",
        body: {
          patient_doc: blob,
          patient_id: patients?.patient_id,
          contract_patient_token: token,
        },
      });
      dispatch(setLoading(false));
      if (!uploadResp.is_error) {
        if (
          uploadResp &&
          uploadResp.result &&
          uploadResp.result.length &&
          uploadResp.result[0].id
        ) {
          setDocID(uploadResp.result[0].id);
          docUrl = uploadResp.result[0].url;
        }
      }
    } else {
      docUrl = patients?.dl_doc;
    }
    // if (docUrl) {
    if (data['patient_ssn']) {
      if (ssncustomValidator(data['patient_ssn']) && process.env.REACT_APP_SERVER !== 'DEMO') {
        dispatch(showToaster({ message: "Invalid SSN, please check and enter valid SSN", type: ToasterType.Danger, status: true }));
        return;
      }
    }
    data["patient_dob"] = data.dob.replace(/\//g, '-');
    data["proof_of_id"] = docUrl ? docUrl : undefined;
    data["address_line_2"] = data.address_line_2 ? data.address_line_2 : undefined;
    data["contract_patient_token"] = token;
    data["patient_mobile_country_code"] = "+1";
    if (data['patient_mobile']) {
      data['patient_mobile'] = (data['patient_mobile'] + '').replace('+1', '');
      data['patient_mobile'] = (data['patient_mobile'] + '').replace(' ', '');
      data['patient_mobile'] = (data['patient_mobile'] + '').replaceAll('-', '');
    }
    let api = "add_patient_detail";
    let disputeType = 0;
    delete data.patient_image;
    delete data.day;
    delete data.month;
    delete data.year;
    if (disputeData && disputeData.details && disputeData.details.length) {
      api = "dispute_debt";
      data["message"] = disputeData["details"];
      if (disputeData["ids"] && disputeData["ids"].length) {
        data["supported_docs"] = disputeData["ids"].join(",");
      }
      if (
        disputeData["selectedReasonIds"] &&
        disputeData["selectedReasonIds"].length
      ) {
        let reasons: any = [];
        disputeData["selectedReasonIds"].forEach((reason: any) => {
          reasons.push(reason.reason_id);
        });
        data["reason_id"] = reasons.join(",");
      }
      disputeType = 1;
    }
    dispatch(saveInfoData(data));
    dispatch(setLoading(true));
    const response = await httpApiCall({
      type: "POST",
      api: "debt/" + api,
      body: data,
    });
    dispatch(setLoading(false));
    if (response.is_error && response.is_error === 1) {
      dispatch(showToaster({ message: response?.original_text || "Something went wrong!", type: ToasterType.Danger, status: true }));
      // toastr.showError(response.original_text);
    } else {
      if (api === "add_patient_detail") {
        const patientData = response.addressInfo && response.addressInfo.patient_id ? response.addressInfo : null;
        dispatch(updatePatients(patientData));
      }
      if (disputeType) {
        navigate("/thanks/" + token);
      } else {
        navigate("/debt-recovery/pay-now/" + token + "?choosePlan=true");
      }
    }
    // }
  };

  // for address
  const getLocation = (location_data: any) => {
    const data = fetchAddress(location_data);
    setValue("patient_address", (data && data.address_line_1) || "");
    setValue("state_name", (data && data.state_name) || "");
    setValue("zip", (data && data.zip) || "");
    setValue("city", (data && data.city) || "");
  };

  // date of birth functionlity
  // const handleDateInput2 = (event: any, currentIndex?: number, key?: any) => {
  //   const value = event.target.value;
  //   const maxLength = currentIndex === 2 ? 4 : 2; // 4 for year, 2 for month and day
  //   const updatedValue = value.slice(0, maxLength);

  //   if (dobKeys.month === key) {
  //     // Month validation
  //     const month = Math.min(Math.max(1, updatedValue), 12); // Restrict month to 1-12
  //     //   updateValue(month.toString().padStart(2, "0")); // Update to 2-digit format
  //     setValue(key, month.toString(), { shouldValidate: true });
  //   } else if (dobKeys.day === key) {
  //     // Day validation
  //     const month = watch("month") || "01";
  //     const year = watch("year") || new Date().getFullYear();
  //     const maxDays = new Date(year, parseInt(month), 0).getDate();
  //     const day = Math.min(
  //       Math.max(1, parseInt(updatedValue || 1, 10)),
  //       maxDays
  //     );
  //     setValue(key, day.toString(), { shouldValidate: true });
  //   } else if (dobKeys.year === key) {
  //     // Year validation
  //     if (updatedValue.toString().length === maxLength) {
  //       const year = parseInt(updatedValue || 1900, 10);
  //       const currentYear = new Date().getFullYear() - 10;
  //       setValue(key, Math.min(Math.max(1900, year), currentYear).toString(), {
  //         shouldValidate: true,
  //       });
  //     }
  //   }

  //   if (updatedValue.length === maxLength) {
  //     moveToNextField(0);
  //   }
  // };
  const handleDateInput = (e: any) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    let formattedValue = value;

    if (value.length > 2 && value.length <= 4) {
      formattedValue = value.slice(0, 2) + "/" + value.slice(2); // Add `/` after month
    } else if (value.length > 4) {
      formattedValue =
        value.slice(0, 2) + "/" + value.slice(2, 4) + "/" + value.slice(4); // Add `/` after day
    }
    if (formattedValue.length > 10) {
      formattedValue = formattedValue.slice(0, 10);
    }

    e.target.value = formattedValue;
  };

  // const moveToNextField = (currentIndex: number) => {
  //   const nextInput = document.getElementById(`dob_${currentIndex + 1}`);
  //   if (nextInput) {
  //     nextInput.focus();
  //   }
  // };

  // on blur add 0 prior to day and month
  // const leavingInput = (event: any, key?: string) => {
  //   const value = event.target.value;
  //   if (
  //     (key === dobKeys.month || key === dobKeys.day) &&
  //     value.toString().length === 1
  //   ) {
  //     setValue(key, "0" + value.toString(), { shouldValidate: true });
  //   }
  // };

  const handleImageChange = (e: any) => {
    const file =
      e?.target?.files && e?.target.files.length ? e?.target.files[0] : null;
    if (file) {
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Only PDF, JPEG, and PNG are allowed.");
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        dispatch(showToaster({ message: 'File size cannot be more than 10MB', type: ToasterType.Danger, status: true }));
        return;
      }

      const reader = new FileReader();
      setFileData(file);
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
    // if (e?.target) {
    //    e.target.value = null;
    // }
  };

  const deleteProfilePic = async () => {
    dispatch(setLoading(true));
    const response = await httpApiCall({
      type: "POST",
      api: "debt/delete_proof",
      body: { doc_id: docId },
    });
    dispatch(setLoading(false));
    setShowConfirmation(false)
    if (response.is_error && response.is_error === 1) {
      // toastr.showError(response.original_text);
      dispatch(showToaster({ message: response?.original_text || "Something went wrong!", type: ToasterType.Danger, status: true }));
    } else {
      setDocID("");
      setPreviewImage(null);
      setFileData(null);
    }
  };

  const deleteProfile = async () => {
    if (docId) {
      setShowConfirmation(true);
      // const response = await httpApiCall({
      //   type: "POST",
      //   api: "debt/delete_proof",
      //   body: { doc_id: docId },
      // });
      // if (response.is_error && response.is_error === 1) {
      //   // toastr.showError(response.original_text);
      //   dispatch(showToaster({ message: response?.original_text || "Something went wrong!", type: ToasterType.Danger, status: true }));
      // } else {
      //   setDocID("");
      //   setPreviewImage(null);
      //   setFileData(null);
      // }
    } else {
      setPreviewImage(null);
      setFileData(null);
    }
  };

  const goBack = () => {
    navigate("/debt-recovery/recovery-plans/" + token);
  };

  // const handleShowPreview = async () => {
  //   if (fileData) {
  //     // Open in a new tab
  //     const fileUrl = previewImage || "/assets/images/payment.png";
  //     const newTab = window.open();
  //     newTab?.document.write(`
  //       <html>
  //         <head><title>File Preview</title></head>
  //         <body style="margin: 0;">
  //           ${
  //             fileData?.name.includes("pdf")
  //               ? `<iframe src="${fileUrl}" width="100%" height="100%"></iframe>`
  //               : `<div style="width: 100%; height: 100%;display:flex; align-items: center;justify-content: center;"><img src="${fileUrl}"/></div>`
  //           }
  //         </body>
  //       </html>
  //     `);
  //   } else {
  //     if (patients?.dl_doc) {
  //       window.open(patients?.dl_doc, '_blank');
  //     }
  //   }
  // }

  const handleShowPreview = async () => {
    if (fileData) {
      const fileUrl = previewImage || "/assets/images/payment.png";
      if (fileData?.name.includes("pdf")) {
        openBase64Pdf(fileUrl);
      } else {
        // Open in a new tab
        const newTab = window.open();
        newTab?.document.write(`
        <html>
          <head><title>File Preview</title></head>
          <body style="margin: 0;">
            <div style="width: 100%; height: 100%;display:flex; align-items: center;justify-content: center;"><img src="${fileUrl}"/></div>
          </body>
        </html>
      `);
      }
    } else {
      if (patients?.dl_doc) {
        window.open(patients?.dl_doc, '_blank');
      }
    }
  }

  const openBase64Pdf = (base64Data: any) => {
    // Convert Base64 to a byte array
    const byteCharacters = atob(base64Data.split(',')[1]); // Remove "data:application/pdf;base64," part
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    
    // Create a Blob and a Blob URL
    const fileBlob = new Blob([byteArray], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(fileBlob);
  
    // Open in a new tab
    window.open(fileUrl, "_blank");
  }


  // const formatPhoneNumber = (value:any) => {
  //   // Remove non-digit characters
  //   const digits = value.replace(/\D/g, "");

  //   // Format as +1 (XXX) XXX-XXXX
  //   if (digits.length > 0) {
  //     let formatted = "+1 ";
  //     if (digits.length >= 1) formatted += `(${digits.slice(0, 3)}`;
  //     if (digits.length >= 4) formatted += `) ${digits.slice(3, 6)}`;
  //     if (digits.length >= 7) formatted += `-${digits.slice(6, 10)}`;
  //     return formatted;
  //   }
  //   return value;
  // };

  return (
    <>
      <div className="pt-4 bg-white h-sm-100">
        <div className="form-wrapper mxw-750 h-100 mx-auto overflow-y-auto hide-scroll-bar">

          <Form
            id="form-id"
            className="container mxw-767"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              {/* <span className="fs-12 fw-700 text-secondary"> Step: 1/2</span> */}
              <h1 className="fs-24 fw-700">Please provide the personal information </h1>
              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    type="text"
                    {...register("patient_first_name")}
                    className="input_field w-100"
                    placeholder=" "
                    autoComplete="patient-first_name"
                  />
                  <label className="input_label">First name</label>
                </div>
                {errors?.patient_first_name?.message &&
                  typeof errors.patient_first_name.message === "string" && (
                    <span className="text-danger fs-12">
                      {errors.patient_first_name.message}
                    </span>
                  )}
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    type="text"
                    {...register("patient_last_name")}
                    className="input_field w-100"
                    placeholder=" "
                    autoComplete="patient-last_name"
                  />
                  <label className="input_label">Last name</label>
                </div>
                {errors?.patient_last_name?.message &&
                  typeof errors.patient_last_name.message === "string" && (
                    <span className="text-danger fs-12">
                      {errors.patient_last_name.message}
                    </span>
                  )}
              </Col>

              <Col md={12} className="mb-3">
                <div className="input_container">
                  <input
                    type="text"
                    {...register("patient_email")}
                    className="input_field w-100"
                    placeholder=" "
                    autoComplete="patient-email"
                  />
                  <label className="input_label">Email address</label>
                </div>
                {errors?.patient_email?.message &&
                  typeof errors.patient_email.message === "string" && (
                    <span className="text-danger fs-12">
                      {errors.patient_email.message}
                    </span>
                  )}
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    type="text"
                    {...register("dob")}
                    onInput={(e) => handleDateInput(e)}
                    className="input_field w-100"
                    placeholder=" "
                    autoComplete="patient-dob"
                  />
                  <label className="input_label">Date of Birth (MM/DD/YYYY)</label>
                </div>
                {errors?.dob?.message &&
                  typeof errors.dob.message === "string" && (
                    <span className="text-danger fs-12">{errors.dob.message}</span>
                  )}
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <Controller
                    name="patient_mobile"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="+1 999-999-9999"
                        maskChar={null}
                        className="input_field w-100"
                        placeholder=" "
                      />
                    )}
                  />
                  {/* <InputMask
                      mask="+1 999-999-9999"
                      maskChar={null}
                      {...register("patient_mobile", { value: "" })}
                      className="input_field w-100"
                      placeholder=" "
                    /> */}
                  <label className="input_label">Phone number</label>
                </div>
                {errors?.patient_mobile?.message &&
                  typeof errors.patient_mobile.message === "string" && (
                    <span className="text-danger fs-12">
                      {errors.patient_mobile.message}
                    </span>
                  )}
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <ReactGoogleAutocomplete
                    apiKey={getGoogleAPIKey()}
                    placeholder=''
                    onPlaceSelected={(place) => getLocation(place)}
                    className="input_field w-100"
                    options={{
                      types: ["geocode"],
                      componentRestrictions: { country: "us" },
                    }}
                    defaultValue={(patients?.patient_address || split_request_data?.patient_address || info?.patient_address) || ''}
                  />
                  <label className="input_label">Address</label>
                </div>
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    type="text"
                    {...register("address_line_2")}
                    className="input_field w-100"
                    placeholder=" "
                  />
                  <label className="input_label">Address line 2 (optional)</label>
                </div>
                {errors?.address_line_2?.message &&
                  typeof errors.address_line_2.message === "string" && (
                    <span className="text-danger fs-12">
                      {errors.address_line_2.message}
                    </span>
                  )}
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    disabled={true}
                    type="text"
                    {...register("zip")}
                    className="input_field w-100"
                    placeholder=" "
                  />
                  <label className="input_label">Zip Code</label>
                </div>
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    disabled={true}
                    type="text"
                    {...register("city")}
                    className="input_field w-100"
                    placeholder=" "
                  />
                  <label className="input_label">City</label>
                </div>
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    disabled={true}
                    type="text"
                    {...register("state_name")}
                    className="input_field w-100"
                    placeholder=" "
                  />
                  <label className="input_label">State</label>
                </div>
              </Col>

              <Col md={6} className="mb-2">
                <div className="input_container">
                  <input
                    type="text"
                    {...register("patient_ssn", {
                      maxLength: 9,
                      pattern: /^[0-9]{1,9}$/
                    })}
                    maxLength={9}
                    className="input_field w-100"
                    placeholder=" "
                    onInput={(e: any) => (e.target.value = e.target.value.replace(/\D/g, "").slice(0, 9))}
                  />
                  <label className="input_label">Social security number (SSN)</label>
                </div>
                {errors?.patient_ssn?.message &&
                  typeof errors.patient_ssn.message === "string" && (
                    <span className="text-danger fs-12">
                      {errors.patient_ssn.message}
                    </span>
                  )}
              </Col>

              <div className="">
                <label className="fs-16 fw-700 mb-0">
                  Proof of ID
                </label>
              </div>
              {!previewImage && !docId && (
                <Col md={12} className="mb-3 d-flex justify-content-between align-items-center">
                    <p className="fs-14 mxw-416 grey-text mt-1 mb-0" style={{ maxWidth: '350px' }}>
                      Upload a clear copy of your Driver’s License or another
                      government-issued proof of ID.
                    </p>
                  <span
                    className="fs-14 fw-500 text-primary cursor"
                    onClick={() => {
                      const fileInput = document.getElementById(
                        "file-upload"
                      ) as HTMLInputElement | null;
                      if (fileInput) {
                        fileInput.click();
                      }
                    }}
                  >
                    <img src="/assets/images/upload-button.png" alt="" />
                  <Form.Group>
                    <Form.Control
                      id="file-upload"
                      type="file"
                      accept=".pdf, .jpeg, .png"
                      {...register("patient_image")}
                      onChange={(e) => {
                        handleImageChange(e);
                        register("patient_image").onChange(e); // Ensures file is registered
                      }}
                      isInvalid={!!errors.patient_image}
                      className="d-none" // Hidden input field
                    />
                  </Form.Group>
                  </span>
                </Col>
              )}

              {(previewImage || docId) && (
                <div>
                  <Col className="d-flex align-items-center justify-content-between bg-lightgrey p-2 mt-2" style={{minHeight: '60px'}}>
                    <div className="d-flex align-items-center">
                      <img className="uploaded-img" src='/assets/images/payment.png' alt="" />
                      <span className="ms-2"> {'Proof of ID'} </span>
                      {/* <span className="ms-2 fw-700 fs-24">.</span> */}
                      <img
                      className="ms-2 cursor"
                      src="/assets/images/eye.png"
                      alt=""
                      onClick={() => handleShowPreview()}
                    />
                    </div>
                    <img
                      className="deleteCross cursor"
                      src="/assets/images/close-circle.png"
                      onClick={deleteProfile}
                      alt=""
                    />
                  </Col>
                </div>
              )}
            </Row>

            {/* <div className='d-flex justify-content-between'>
               <Button className='btn btn-primary p-0'><Link className='d-flex justify-content-center align-items-center h-100 w-100' style={{color: '#fff', textDecoration: 'none'}} to={'/debt-recovery/recovery-plans/' + token}>Back</Link></Button>
               <Button variant="secondary" type="submit" className="mnw-125">
                  Save
               </Button>
            </div> */}

            <div className="d-flex justify-content-between align-items-center mb-4">
              <button type="button" style={{ padding: '12px 23px' }} className='btn btn-outline mt-3' onClick={() => goBack()}>
                <img className=" cursor me-3" src="/assets/images/left-arrow.svg" alt="" />
                Back
              </button>

              <button disabled={!previewImage && !docId} type="submit" style={{ padding: '12px 23px' }} className='btn btn-secondary mt-3'>
                Continue
              </button>
            </div>
          </Form>
          {/* <ContinueFooter
            back={() => goBack()}
            disable={!previewImage && !docId}
            value="Continue"
            type="submit"
            form="form-id"
          /> */}
        </div>

        {/* <Modal centered show={showImageModal} onHide={() => setShowImageModal(false)}>
          <Modal.Header className='border-0 px-4' closeButton>
            <Modal.Title> {fileData?.name} </Modal.Title>
          </Modal.Header>
          <Modal.Body className='px-4 '>
            <iframe
              src={previewImage || patients?.dl_doc}
              title="PDF Preview"
              width="100%"
              height="500px"
            ></iframe>
          </Modal.Body>
        </Modal> */}

        <Modal centered show={showConfirmation} onHide={() => setShowConfirmation(false)}>
          <Modal.Header className='border-0 px-4' closeButton>
            <Modal.Title> Do you really want to delete this document ? </Modal.Title>
          </Modal.Header>
          <Modal.Body className='px-4 '>
            <button onClick={() => setShowConfirmation(false)} className="btn btn-secondary me-4">No</button>
            <button onClick={() => deleteProfilePic()} className="btn btn-primary">Yes</button>
          </Modal.Body>
        </Modal>
        <CopyRightFooter />
      </div>
    </>
  );
};

export default CustomerDetails;
