import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import LayoutLazyComponent from './components/LayoutComponent/LayoutComponent.lazy';
import InvalidComponent from './components/InvalidComponent/InvalidComponent.lazy';
import HomeComponent from './components/HomeComponent/HomeComponent.lazy';
import PlanCreated from './components/PlanCreated/PlanCreated.lazy';
import LinkExpire from './components/LinkExpire/LinkExpire.lazy';
import DebtRecuvery from './components/Debt-recuvery/DebtRecuvery/DebtRecuvery.lazy';
import CustomerDetails from './components/Debt-recuvery/CustomerDetails/CustomerDetails';
import DisputeDebt from './components/Debt-recuvery/DisputeDebt/DisputeDebt';
import PayNowComponent from './components/HomeComponent/PayNowComponent/PayNowComponent';
import PayPartial from './components/Debt-recuvery/PayPartial/PayPartial.lazy';
import RecoveryPlansComponent from './components/Debt-recuvery/RecoveryPlansComponent/RecoveryPlansComponent.lazy';
import ErrorPage from './components/error/error.component';
import { TermsTemplatePage } from './components/Terms/DisclosureForm.tsx.tsx';
import { HippaTerms } from './components/Terms/HippaComponent.tsx';

const RouterComponent: React.FC = () => {

  // Create the router instance
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
          <Route path="/hipaa/:token" element={<HippaTerms />} />
          <Route path="/tila/:token/:start_date" element={<TermsTemplatePage />} />
          <Route element={<LayoutLazyComponent />} errorElement={<ErrorPage />}>
          <Route path="/" element={<InvalidComponent />} />
          <Route path="/invalid" element={<InvalidComponent />} />
          <Route path="/pay-now/:token" element={<HomeComponent />} />
          <Route path="/plan-created/:token" element={<PlanCreated />} />
          <Route path="/link-expire" element={<LinkExpire />} />
          <Route path="debt-recovery">
            <Route path=":token" element={<DebtRecuvery />} />
            <Route path="paypartial/:token" element={<PayPartial />} />
            <Route path="pay-now/:token" element={<PayNowComponent />} />
            <Route path="recovery-plans/:token" element={<RecoveryPlansComponent />} />
            <Route path="customer-details/:token" element={<CustomerDetails />} />
            <Route path="dispute-debt/:token" element={<DisputeDebt />} />
            {/* <Route path="payment-successfull/:token" element={<PaymentSuccessfull />} /> */}
          </Route>
        </Route>
        <Route path="*" element={<InvalidComponent />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default RouterComponent;