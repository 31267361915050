import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { useParams } from "react-router-dom";
import httpApiCall from "../../service/api.service";
import { addPaymentDetail } from "../../Redux/slices/payment.slice";

export const HippaTerms = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    async function callApi() {
      const params = {
        type: "GET" as "GET",
        body: {
          contract_patient_token: token,
        },
        api: "proposal/check_email_code_patient",
      };
      setLoading(true);
      const response = await httpApiCall(params);
      setLoading(false);
      console.log(response, "response");

      if (!response.is_error && response["flag"] === 200) {
        if (
          response &&
          response.split_request_data &&
          response.split_request_data.length &&
          response.split_request_data[0].contract_service_record &&
          typeof response.split_request_data[0].contract_service_record ===
            "string"
        ) {
          response.split_request_data[0].contract_service_record = JSON.parse(
            response.split_request_data[0].contract_service_record
          );
        }
        dispatch(addPaymentDetail(response));
      } else {
        //  TODO: error here
      }
    }

    if (token) {
      callApi();
    } else {
      console.error("Token is missing in the URL path");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { doctor_country_currency_info, split_request_data } = useSelector(
    (state: RootState) => state.paymentDetails || {}
  );

  let patient_name =  (split_request_data?.patient_first_name || split_request_data?.patient_last_name)
    ? `${split_request_data.patient_first_name} ${split_request_data.patient_last_name}` : " ________________________ ";

  return (
    <>
      {loading && (
        <div
          style={{ visibility: loading ? "visible" : "hidden", zIndex: 1060 }}
          className={`overlay position-fixed p-3 h-100 w-100 d-flex justify-content-center align-items-center `}
        >
          <div className="fm-spinner p-3 d-flex justify-content-center align-items-center rounded-circle">
            <img src="/assets/images/logo-icon.svg" alt="" className="h-100" />
          </div>
        </div>
      )}
      <div className="container animated fadeIn consent-form">
        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
          <div
            id="A4"
            style={{
              background:
                "rgba(245, 246, 250, 1.0);padding: 30px;max-width:970px",
            }}
          >
            <div
              className="A4"
              style={{
                height: "1120px",
                maxWidth: "828px",
                margin: "auto",
                display: "table",
                background: "rgba(245, 246, 250, 1.0)",
              }}
            >
              <div
                className="header"
                style={{
                  textAlign: "center",
                  height: "70px",
                  width: "100%",
                  padding: "0",
                  backgroundColor: "#30346c",
                  paddingTop: "1px"
                  
                }}
              >
                <img
                  src="/assets/images/fm-logo.svg"
                  width="120"
                  style={{
                    margin: "20px auto",
                    display: "block", // Added display block to center the image
                  }}
                />
              </div>

              <div className="content p-30">
                <h3
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    fontSize: "20px",
                    marginBottom: "10px",
                    marginTop: "23px",
                    borderBottom: "1px solid grey",
                    width: "max-content",
                  }}
                >
                  ACH / Card Payment Authorization
                </h3>

                <p
                  style={{
                    lineHeight: "2",
                    padding: "15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  I,{" "}
                  <strong
                    style={{
                      paddingBottom: "2px",
                      borderBottom: "0px solid #656565",
                    }}
                  >
                    {patient_name}
                  </strong>{" "}
                  authorized signer on this account. I authorize Finance Mutual
                  to make regularly scheduled charges to my Credit Card/Bank
                  account in the amount of my Finance Mutual statement balance
                  for my account, on the payment date indicated. I understand
                  and agree that Finance Mutual will debit my Credit Card/Bank
                  account each month via Automatic Clearing House (ACH) debit,
                  even if I have made other payments between the statement date
                  and the scheduled payment date, including if I paid the
                  statement balance. These charges will appear on my payment
                  methods monthly statement and can be reviewed anytime through
                  the Finance Mutual customer portal.
                </p>

                <div
                  style={{
                    lineHeight: "2",
                    padding: "0 15px 15px 15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  By enrolling in Finance Mutual’ recurring Automatic Monthly
                  Payment Program ("Automatic Monthly Payment Program"), I also
                  acknowledge the following:
                  <ul style={{ marginTop: "12px" }}>
                    <li>
                      If the scheduled date of any electronic funds transfer
                      ("EFT") falls on a weekend or holiday, my EFT can be
                      initiated as of the next business day.
                    </li>
                    <li>
                      If any EFT is returned or dishonored for any reason,
                      including non-sufficient funds, a stop payment order, or a
                      closed account, Finance Mutual may at its discretion
                      attempt to process the charge again. I further agree to an
                      additional{" "}
                      <span className="notranslate">
                        {" "}
                        {doctor_country_currency_info?.currency?.length
                          ? doctor_country_currency_info?.currency[0]
                              ?.display_logo
                          : ""}{" "}
                        25
                      </span>{" "}
                      charge for each returned or dishonored EFT which will be
                      initiated as a separate transaction from the authorized
                      recurring payment.
                    </li>
                    <li>
                      I agree not to dispute this recurring billing with my
                      financial institution so long as the transactions
                      correspond to the terms indicated in this authorization
                      form.
                    </li>
                    <li>
                      I understand that this authorization will remain in effect
                      unless I cancel it in writing, and I agree to notify
                      legal@financemutual.com in writing of any changes in my
                      account information or termination of this authorization
                      at least 15 days prior to the next billing date.
                    </li>
                  </ul>
                </div>

                <h4
                  style={{
                    padding: "0 15px",
                    marginBottom: "0",
                    marginTop: "19px",
                  }}
                >
                  Payment for Service
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "0 15px",
                    marginTop: "0px",
                  }}
                >
                  I accept that I am financially responsible for all services
                  rendered on my behalf for which a charge may be associated. I
                  accept personal responsibility for all payments, down
                  payments, late fees, plus any collection costs for amounts
                  personally owed by me.
                </p>
                <h4
                  style={{
                    padding: "0 15px",
                    marginBottom: "0",
                    marginTop: "19px",
                  }}
                >
                  Unpaid Account Balances
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "0 15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  I acknowledge that if I fail to make payments for services
                  rendered, my account may be turned over to a collection
                  agency. I will be responsible to pay the collection agency’s
                  fees that may be incurred in the collection of any outstanding
                  balance.
                </p>

                <h4
                  style={{
                    padding: "0 15px",
                    marginBottom: "0",
                    marginTop: "19px",
                  }}
                >
                  Credit Reporting
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "0 15px",
                    marginBottom: "0px",
                    marginTop: "0",
                  }}
                >
                  Finance Mutual may report my payment activities to credit
                  bureaus as an installment payment to help build credit.
                  Finance Mutual may also report my defaulted debt to credit
                  bureaus after payment is 31 days or more past due.
                </p>

                <h4 style={{ padding: "0 15px", marginTop: "30px" }}>
                  Agreement to Accept Terms and Conditions, Periodic Statements,
                  Notices, Confirmations and Disclosures in an Electronic
                  Format.
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  <span>
                    This Agreement, the terms "we", "us" and "our" refer to
                    Finance Mutual Corporation ("Finance Mutual"), and the terms
                    "you" and "your" refer to you as a Finance Mutual customer.
                    This Agreement applies to all Finance Mutual accounts
                    established with you (each is an "Account").
                  </span>
                  <span
                    style={{
                      display: "block",
                      lineHeight: "2",
                      padding: "7px 0",
                      marginBottom: "0px",
                      marginTop: "0px",
                    }}
                  >
                    This Electronic Disclosure and Consent contains important
                    information that you are entitled to receive before you
                    consent to transact business with us electronically.
                  </span>
                </p>

                <h4 style={{ padding: "0 15px", margin: "0" }}>
                  Electronic Acceptance of Account Terms.
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  You acknowledge and agree that (a) the Terms and Conditions
                  applicable to your Account(s) (the "Account Terms"), any
                  amendments to the Account Terms, and any one-time
                  authorization for an electronic funds transfer ("EFT") or
                  preauthorization for EFTs to pay amounts due and payable under
                  my Account can be entered into electronically and enforceable
                  in accordance with laws applicable to the recognition of
                  electronic signatures, including without limitation the
                  Electronic Signatures in Global and National Commerce Act (the
                  E-SIGN Act); (b) the electronic signature utilizing the
                  designated method constitutes your signature, acceptance, and
                  agreement the same as if actually signed in writing; and (c)
                  this Agreement shall constitute an "original" document when
                  printed from electronic files and records established and
                  maintained by either party in the normal course of business.
                </p>
                <h4
                  style={{ padding: "0 15px", margin: "0", marginTop: "30px" }}
                >
                  Consent to Receive Records in an Electronic Format
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "0px 15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  <span>
                    By clicking your acceptance below, you hereby agree to
                    electronic delivery of notices and records relating to your
                    Account ("Records") in lieu of receiving such Records in
                    paper form through postal delivery and not otherwise
                    required by regulation or policy to be sent exclusively by
                    postal delivery. Records eligible for electronic delivery
                    include periodic statements of your Account. By agreeing to
                    electronic delivery, you will not receive these disclosures
                    by mail.
                  </span>

                  <span
                    style={{
                      display: "block",
                      lineHeight: "2",
                      padding: "7px 0",
                      marginBottom: "0px",
                      marginTop: "0px",
                    }}
                  >
                    We may always, in our sole discretion, provide you with any
                    communication of Records in writing, even if you have chosen
                    to receive it electronically.
                  </span>
                  <span
                    style={{
                      display: "block",
                      lineHeight: "2",
                      padding: "7px 0",
                      marginBottom: "0px",
                      marginTop: "0px",
                    }}
                  >
                    You acknowledge and agree that your consent above is being
                    provided in connection with a transaction affecting
                    interstate commerce that is subject to the E-SIGN Act, and
                    that you and we both intend that the E-SIGN ACT apply to the
                    maximum extent to permit us the ability to conduct business
                    with you by electronic means.
                  </span>
                </p>

                <h4
                  style={{ padding: "0 15px", margin: "0", marginTop: "20px" }}
                >
                  Changes of Terms; Termination
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "0px 15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  We reserve the right, at any time and in our sole discretion,
                  to change the terms and conditions under which we provide
                  Records to you electronically, or to discontinue providing
                  Records to you electronically. We will provide you with
                  advance notice of any such change or termination. Your
                  decision to continue to receive Records electronically will
                  constitute your agreement to any such change.
                </p>
                <h4
                  style={{ padding: "0 15px", margin: "0", marginTop: "20px" }}
                >
                  HIPAA (Health Insurance Portability and Accountability Act)
                  Disclosure
                </h4>
                <p
                  style={{
                    lineHeight: "2",
                    padding: "0px 15px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  I consent that if{" "}
                  <strong
                    style={{
                      paddingBottom: "2px",
                      borderBottom: "0px solid #656565",
                    }}
                  >
                    {split_request_data?.doctor_name} 
                  </strong>
                  {" "}is a medical
                  provider of any sort, it may use and disclose Protected Health
                  Information (PHI) or Individually Identifiable Health
                  Information (IIHI) about me to carry out Treatment, Payment,
                  and Healthcare operations (TPO). I,{" "}
                  <strong
                    style={{
                      paddingBottom: "2px",
                      borderBottom: "0px solid #656565",
                    }}
                  >
                    {patient_name}
                  </strong>
                  , authorize{" "}
                  <strong
                    style={{
                      paddingBottom: "2px",
                      borderBottom: "0px solid #656565",
                    }}
                  >
                    {split_request_data?.doctor_name}
                  </strong> to release my
                  protected health information to Finance Mutual.
                </p>
                <br />
                <p
                  style={{
                    width: "50%",
                    minHeight: "20px",
                    float: "left",
                    padding: "0",
                    margin: "0 15px",
                    fontWeight: "bold",
                    paddingBottom: "2px",
                    borderBottom: "1px solid #656565",
                  }}
                >
                  {patient_name}
                </p>
                <div className="clearfix"></div>
                <p style={{ padding: "0 15px 0" }}>Name (printed)</p>
              </div>
              <div className="footer p-3">
                <span>
                  ACH / Card Payment Authorization&nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
                <span>support@financemutual.com&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span>Finance Mutual LLC, 1 Technology Dr Ste I821, Irvine CA 92618-2319, Irvine, CA 92618-2319, United States</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
