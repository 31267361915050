import React, { useEffect, useState,useRef } from 'react';
import html2canvas from 'html2canvas';
import { Row, Col, Button, Form, Dropdown, OverlayTrigger, Modal } from 'react-bootstrap';
import { changePreviewStep, saveToPayToday, setContractCode, setExixtingCard, updatePatients } from '../../../Redux/slices/payment.slice';
import { AppDispatch, RootState } from '../../../Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { patient_details, patientPaymentSourceType, paymentMatrix, PaymentMatrixType, PaymentMatrixTypeEnum, PaymentMethodType, PaymentParams, paymentSourceType, PreviewSteps, ToasterType } from '../../../types';
import './PayNowComponent.scss';
import SignatureCanvas from "react-signature-canvas";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Wrapper } from '../../ModalComponent/StripeModal';
import { ConfirmationModal } from '../../ModalComponent/ConfirmationModal';
import { ExistingPaymentSources } from '../../ModalComponent/ExistingPaymentSources';
import { base64ToBlob, closeExisingPaymentSourceModal, formatNumber, getDateSuffix, getItem, renderHtMLForTotalPayable, responseType, TooltipFn } from '../../../service/shared.service';
import httpApiCall from '../../../service/api.service';
// import toastrSer from '../../../service/toast.service';
import { setFinancingState, setLoading } from '../../../Redux/slices/loader.slice';
import { loadStripe } from '@stripe/stripe-js';
import { socket } from '../../../service/socket.service';
// import { formatDateNew } from '../../../utils/dateformatter';
// import { showToaster } from '../../../Redux/slices/toaster.slice';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CopyRightFooter from '../../LayoutComponent/copyrightfooter/CopyRightFooter';
import PaymentSuccessfull from '../../Debt-recuvery/PaymentSuccessfull/PaymentSuccessfull';
import ContinueFooter from '../../LayoutComponent/continueFooter/ContinueFooter';
import { showToaster } from '../../../Redux/slices/toaster.slice';
import { formatDateNew } from '../../../utils/dateformatter';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_51QU5GSG0qhxL6Qheuur8KNocxZbXVns1qvqrb9kYW3Z7E2KOzG18w86PYzMWjGqVXuD4YYFcESN351spqp7w4D1Q00EAdpkY6W');
const PayNowComponent: React.FC = () => {
  let sigCanvas: any = {};

  const [isChecked, setIsChecked] = useState(false);
  const [eSignature, setESignature] = useState(false);
  const fontFamilyList: any = ['Dancing Script, cursive', 'Playwrite AR, cursive', 'Caveat, cursive', 'Playwrite AT, cursivee', 'Playwrite HR, cursive', '"Playwrite HU", cursive'];
  const [to_pay_today, set_to_pay_today] = useState<any>();
  // const [choose_plan, set_choose_plan] = useState<boolean>(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState<any>(null);
  const [selectedFont, setSelectedFont] = useState(fontFamilyList[0]);
  const [renderHTML, setRenderHTML] = useState<React.ReactNode>(null);
  const [paymentData, setPaymentData] = useState({
    generateSignature: false,
  });

  const [iphoneDevice, setIphoneDevice] = useState(false);

  const [payment_sources, set_payment_sources] = useState<patientPaymentSourceType[]>();
  const [patient_details, set_patient_details] = useState<patient_details>();

  // for stripe modal
  const [showAddNewCardModal, setAddNewCardModal] = useState(false);

  // for confirmation modal
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showExistingCardModal, setShowExistingCardModal] = useState(false);
  const [paymentParams, setPaymentParams] = useState<PaymentParams>();
  const [paymentMethods, setPaymentMethods] = useState<patientPaymentSourceType[]>();
  const [selectedCards, setSelectedCards] = useState<paymentSourceType>({
    primary: paymentMatrix,
    backup: paymentMatrix,
    recurring: paymentMatrix
  });
  const [payment_matrix_type, setPaymentMatrix_type] = useState('');
  const { selectedPaymentPlan, doctor_country_currency_info, patients, split_request_data } = useSelector((state: RootState) => state.paymentDetails || {});
  const { financeOnBusinessState } = useSelector((state: RootState) => state.loaderReducer || {});
  // const toastr = new toastrSer(true);
  const dispatch = useDispatch<AppDispatch>();
  const { token } = useParams<{ token: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tomorrow] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [recurring_date, set_recurring_date] = useState<any>();
  const [max_recurring_date, set_max_recurring_date] = useState<any>();
  const [debtPayInFull, setDebtPayInFull] = useState(false);
  const [debtRecoveryType, setDebtRecoveryType] = useState(false);
  const { addedCard } = useSelector((state: RootState) => state.paymentDetails || {});
  const [show, setShow] = useState(false);
  const [debtPaymentSuccessSourceType, setdebtPaymentSuccessSourceType] = useState(0);
  const handleCloseModal = () => {
    setShow(false);
    setPaymentMatrix_type('');
  }
  const handleShowModal = (payment_matrix_type: string) => {
    setPaymentMatrix_type(payment_matrix_type);
    setShow(true);
  }
  const datepickerRef = useRef<DatePicker | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!token || !split_request_data || (split_request_data && !split_request_data.treatment_amount)) {
      navigate("/debt-recovery/" + token);
      return;
    }
    if (window.innerWidth <= 575) {
      setIsSmallScreen(true);
    }
    // setTimeout(() => {
    //   if (containerRef && containerRef.current) {
    //     const inputs = containerRef.current.querySelectorAll<HTMLInputElement>(".date-picker-input");
    //     inputs.forEach((input: any) => {
    //       input.readOnly = true; // Set readOnly attribute
    //     });
    //   }
    // }, 1000)

    if (patients && patients.payment_sources && patients.payment_sources.length) {
      set_payment_sources(patients?.payment_sources)
      if (addedCard) {
        const filterItem = patients.payment_sources.filter((item: patientPaymentSourceType) => item.last4 === addedCard.last4);
        if (!filterItem || (filterItem && filterItem.length)) {
          setExixtingCard(null);
        } else if (addedCard) {
          setSelectedCards(addedCard)
        }
      } else {
        setExixtingCard(null);
      }
    } else {
      setExixtingCard(null);
    }
    let newDate = new Date();
    let maxDays = 30;
    if (split_request_data && split_request_data['first_recurring_days']) {
      maxDays = split_request_data['first_recurring_days'];
    }
    newDate.setDate(newDate.getDate() + maxDays); // Set the date to one month in the future

    // Format the date as 'YYYY-MM-DD'
    const formattedDate = newDate.toISOString().split('T')[0];
    set_recurring_date(formattedDate);
    set_max_recurring_date(formattedDate);
    const userAgent = navigator.userAgent;
    // Check for iOS devices (iPhone, iPad)
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setIphoneDevice(true);
    }

    if (patients && patients.patient_first_name) {
      const obj: patient_details = {
        patient_name: patients.patient_first_name + ' ' + patients.patient_last_name,
        city: patients?.city || split_request_data?.city || '',
        state: patients?.patient_state || split_request_data?.patient_state || split_request_data?.state || '',
        postal_code: split_request_data?.zip || '',
        line1: patients?.patient_address || split_request_data?.patient_address || ''
      }
      set_patient_details(obj);
    } else if (split_request_data && split_request_data.patient_first_name) {
      const data = split_request_data;
      const obj: patient_details = {
        patient_name: data.patient_first_name + ' ' + data.patient_last_name,
        city: data.city || '',
        state: data.state || '',
        postal_code: data.zip || '',
        line1: data?.patient_address || ''
      }
      set_patient_details(obj);
    }
    if (selectedPaymentPlan) {
      const renderData = renderHtMLForTotalPayable({...selectedPaymentPlan, payment_reserve_on_pay_new_ten: split_request_data?.payment_reserve_on_pay_new_ten}, doctor_country_currency_info, formatNumber(split_request_data?.downpayment_amount));
      setRenderHTML(renderData);
    }
    if (financeOnBusinessState && (financeOnBusinessState.contract_patient_token === token)) {
      dispatch(setFinancingState({ contract_patient_token: '' }));
      navigate('/link-expire/');
    }
    setTimeout(() => {
      saveSuggestedSignature(false)
    }, 1000)

    if (window.location.href.includes('choosePlan')) {
      let totalAmt = Number(selectedPaymentPlan?.to_pay_today);
      if (getItem('partial_amount')) {
        totalAmt = Number(getItem('partial_amount'));
      }
      // set_choose_plan(true);
      set_to_pay_today(totalAmt.toFixed(2));
      dispatch(saveToPayToday(totalAmt));
    } else if (getItem('pay_in_full') && !JSON.parse(getItem('pay_in_full'))) {
      // set_choose_plan(false);
      set_to_pay_today(selectedPaymentPlan?.to_pay_today?.toFixed(2));
      dispatch(saveToPayToday(selectedPaymentPlan?.to_pay_today));
    }
    if (getItem('pay_in_full') && JSON.parse(getItem('pay_in_full'))) {
      const amount = Number(split_request_data?.treatment_amount) + Number(split_request_data?.paid_full_transaction_fee || 0);
      set_to_pay_today(amount.toFixed(2));
      dispatch(saveToPayToday(amount));
      setDebtPayInFull(true);
    }
    if (window.location.href.includes('/debt-recovery')) {
      setDebtRecoveryType(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [split_request_data, selectedPaymentPlan, financeOnBusinessState])

  const changeStep = () => {
    // console.log(PreviewSteps.Step1);

    // navigate("/debt-recovery/customer-details/" + token);
    dispatch(changePreviewStep(PreviewSteps.Step1));
  }

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckboxChange2 = (event: any) => {
    setESignature(event.target.checked);
  };

  // updates the paymentData state
  const finalPaymentData = (data: any, type: string) => {
    if (type === 'date') {
      set_recurring_date(new Date(data))
    } else {
      setTrimmedDataURL(null);
      setPaymentData((stateData: any) => ({
        ...stateData,
        generateSignature: data.target.checked,
      }))
      if (data.target.checked) {
        setTimeout(() => {
          saveSuggestedSignature();
        }, 500)
      }
    }
    // console.log(paymentData.generateSignature);
  }

  const handleShow = (payment_matrix_type: string, payment_source_type: number) => {
    setShow(false);
    setPaymentMatrix_type(payment_matrix_type);
    setPaymentParams({
      payment_matrix_type: payment_matrix_type as PaymentMatrixType,
      payment_source_type,
      contract_patient_token: token
    })
    const banks = payment_sources?.filter((item: any) => item['payment_source_type'] === 1);
    const cards = payment_sources?.filter((item: any) => item['payment_source_type'] !== 1);
    let paymentSources: patientPaymentSourceType[] = [];
    if (payment_source_type === PaymentMethodType.CARD && cards && cards.length) {
      paymentSources = cards;
      setPaymentMethods(cards);
    } else if (payment_source_type === PaymentMethodType.BANK && banks && banks.length) {
      paymentSources = banks;
      setPaymentMethods(banks);
    }
    if (paymentSources && paymentSources.length) {
      setShowExistingCardModal(true);
    } else {
      setAddNewCardModal(true);
    }
  }

  const clearSignature = () => {
    sigCanvas.clear();
    setTrimmedDataURL(null);
  }

  const getSignature = () => {
    if (sigCanvas) {
      setTrimmedDataURL(sigCanvas.getTrimmedCanvas().toDataURL());
    }
  }

  const saveSuggestedSignature = (showError: boolean = true) => {
    const pElement = document.getElementById('signature-text');
    if (pElement) {
      html2canvas(pElement, { useCORS: true }).then((canvas) => {
        setTrimmedDataURL(canvas.toDataURL());
      }).catch((error) => {
        console.error('Error generating canvas:', error);
      });
    } else if (showError) {
      dispatch(showToaster({ message: 'Please select the signature', type: ToasterType.Danger, status: true }));
      return;
    }
  };

  // updates payment sources and selected cards
  const setData = (params: patientPaymentSourceType) => {
    if (params) {
      let paymentSources: patientPaymentSourceType[] = payment_sources ? [...payment_sources] : [];
      let data = paymentSources.find((item: patientPaymentSourceType) => item.last4 === params.last4);
      if (!data || (data && !data.last4)) {
        paymentSources.push(params);
        set_payment_sources(paymentSources)
      }
      if (payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY) {
        setSelectedCards((cards: any) => ({
          ...cards,
          primary: params
        }));
      } else if (payment_matrix_type === PaymentMatrixTypeEnum.RECURRING) {
        setSelectedCards((cards: any) => ({
          ...cards,
          recurring: params
        }));
      } else if (payment_matrix_type === PaymentMatrixTypeEnum.BACKUP) {
        setSelectedCards((cards: any) => ({
          ...cards,
          backup: params
        }));
      }
    }
  }

  useEffect(() => {
    dispatch(setExixtingCard(selectedCards));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCards]);

  const hide = async (params: patientPaymentSourceType) => {
    if (params && !params.is_error) {
      let patientData: any = patients;
      if (patientData && patientData.payment_sources && patientData.payment_sources.length) {
        patientData = {
          ...patientData,
          payment_sources: [
            ...patientData?.payment_sources,
            params,
          ],
        }
        set_payment_sources(patientData?.payment_sources)
      } else {
        patientData = {
          ...patientData,
          payment_sources: [
            params
          ],
        }
      }
      dispatch(updatePatients(patientData));
      if (payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY && (!selectedCards.recurring || (selectedCards.recurring && !selectedCards.recurring['last4']))) {
        setAddNewCardModal(false);
        if (!debtPayInFull) {
          setTimeout(() => {
            setConfirmationModal(true);
          })
        }
      } else {
        setAddNewCardModal(false);
      }
    } else {
      if (params && params.is_error === 1) {
        setAddNewCardModal(false);
        setTimeout(() => {
          setAddNewCardModal(true);
        }, 500)
      } else {
        setAddNewCardModal(false);
      }
    }
    setData(params);
  }

  const checkRecurringPrimarySame = (resp: string) => {
    if (resp === responseType.KEEPSAME) {
      setSelectedCards((cards: any) => ({
        ...cards,
        recurring: selectedCards.primary
      }));
    } else if (resp === responseType.ADDNEW) {
      setPaymentMatrix_type(PaymentMatrixTypeEnum.RECURRING);
      setPaymentParams({
        payment_matrix_type: PaymentMatrixTypeEnum.RECURRING,
        payment_source_type: PaymentMethodType.CARD,
        contract_patient_token: token
      })
      setAddNewCardModal(true);
    }
    setConfirmationModal(false);
  }

  const existingModalHide = (type: string, data: any) => {
    // check if add new card is triggered from existing cards modal
    if (closeExisingPaymentSourceModal.ADD_CARD === type || closeExisingPaymentSourceModal.ADD_BANK === type) {
      let params = JSON.parse(JSON.stringify(paymentParams));
      params.payment_source_type = (closeExisingPaymentSourceModal.ADD_CARD === type ? PaymentMethodType.CARD : PaymentMethodType.BANK);
      setPaymentParams(params);
      setTimeout(() => {
        setShowExistingCardModal(false);
        setAddNewCardModal(true);
      })
    }

    // check if modal is just closed from existing cards modal
    if (closeExisingPaymentSourceModal.CLOSE_MODAL === type) {
      setShowExistingCardModal(false);
    }

    // check if modal is closed with source id from existing cards modal
    if (closeExisingPaymentSourceModal.SELECTED_CARD === type) {
      let paymentData: any;
      if (payment_sources && payment_sources.length) {
        paymentData = payment_sources.find((item: patientPaymentSourceType) => item.source_id === data);
      }
      setData(paymentData);
      setShowExistingCardModal(false);
    }

    if (type === "ADD_BANK") {
      setShowExistingCardModal(false);
      handleShow(payment_matrix_type, PaymentMethodType.BANK);
    }
  }

  const saveFinancingError = async (error_message: string, error_type: string) => {
    const tracking_data = {
      contract_id: '',
      split_req_id: split_request_data?.split_req_id || '',
      error_message: error_message || '',
      error_type: error_type || '',
      payment_intent_id: '',
      contract_token: token,
      patient_id: '',
      pc_code: ''
    };

    await httpApiCall({ type: 'POST', api: 'proposal/save_financing_errors', body: tracking_data });
    dispatch(setLoading(false));
    dispatch(showToaster({ message: error_message, type: ToasterType.Danger, status: true }));
    return true;
  }

  // when pay in full for debt recovery
  const paidFull = async () => {
    if (selectedCards && !selectedCards.primary.source_id) {
      dispatch(showToaster({ message: 'Please add atleast one payment method', type: ToasterType.Danger, status: true }));
      return;
    }
    if (!eSignature) {
      dispatch(showToaster({ message: 'Please read and accept terms', type: ToasterType.Danger, status: true }));
      return;
    }

    if (!isChecked) {
      dispatch(showToaster({ message: 'Please read and accept terms', type: ToasterType.Danger, status: true }));
      return;
    }


    if (!trimmedDataURL && !paymentData.generateSignature) {
      dispatch(showToaster({ message: 'Signature is missing', type: ToasterType.Danger, status: true }));
      return;
    }

    dispatch(setLoading(true));
    const blob = await base64ToBlob(trimmedDataURL);
    const uploadResp = await httpApiCall({ type: 'form', api: 'proposal/upload_signature', body: { patient_signature: blob } })
    if (!uploadResp.is_error) {
      callPaymentFunctions(uploadResp, '/debt/paid_full');
    } else {
      setLoading(false);
      dispatch(showToaster({ message: 'Wrong file', type: ToasterType.Danger, status: true }));
    }
  }

  // create_contract here
  const createContract = async () => {
    if (selectedCards && !selectedCards.primary.source_id && selectedPaymentPlan?.to_pay_today && Number(selectedPaymentPlan?.to_pay_today) > 0) {
      dispatch(showToaster({ message: 'Please select or add primary card', type: ToasterType.Danger, status: true }));
      return;
    }

    if (selectedCards && !selectedCards.recurring.source_id) {
      dispatch(showToaster({ message: 'Please select or add recurring card', type: ToasterType.Danger, status: true }));
      return;
    }

    if (selectedCards && (selectedCards.recurring.source_id && selectedCards.backup && selectedCards.backup.source_id && selectedCards.recurring.source_id === selectedCards.backup.source_id)) {
      dispatch(showToaster({ message: 'Recurring and backup card cannot be same', type: ToasterType.Danger, status: true }));
      return;
    }
    if (!eSignature) {
      dispatch(showToaster({ message: 'Please read and accept terms', type: ToasterType.Danger, status: true }));
      return;
    }

    if (!isChecked) {
      dispatch(showToaster({ message: 'Please read and accept terms', type: ToasterType.Danger, status: true }));
      return;
    }
    if (!trimmedDataURL && !paymentData.generateSignature) {
      dispatch(showToaster({ message: 'Signature is missing', type: ToasterType.Danger, status: true }));
      return;
    }

    dispatch(setLoading(true));
    const blob = await base64ToBlob(trimmedDataURL);
    const uploadResp = await httpApiCall({ type: 'form', api: 'proposal/upload_signature', body: { patient_signature: blob } })
    if (!uploadResp.is_error) {
      callPaymentFunctions(uploadResp, 'proposal/create_contract');
    } else {
      setLoading(false);
      dispatch(showToaster({ message: 'Wrong file', type: ToasterType.Danger, status: true }));
    }
  }

  const callPaymentFunctions = async (uploadResp: any, api: string) => {
    const data: any = uploadResp;
    const specificDate = new Date(recurring_date);
    const year = specificDate.getFullYear();
    const month = String(specificDate.getMonth() + 1).padStart(2, '0');
    const day = String(specificDate.getDate()).padStart(2, '0');
    const body = {
      contract_patient_token: token,
      patient_signature: data['patient_signature'],
      start_date: `${year}-${month}-${day}`,
      source_id: selectedCards.primary.source_id ? selectedCards.primary.source_id : selectedCards.recurring.source_id,
      recurring_source_id: selectedCards.recurring.source_id ? selectedCards.recurring.source_id : undefined,
      backup_source_id: selectedCards.backup && selectedCards.backup.source_id ? selectedCards.backup.source_id : undefined,
      contract_type: patients?.contract_type || split_request_data?.contract_type,
      payment_source_type: selectedCards.primary.payment_source_type ? (selectedCards.primary.payment_source_type + '') : PaymentMethodType.DEBIT
    }
    // const data = {
    const response = await httpApiCall({ type: 'POST', api, body });
    if (!response.is_error) {
      let error_message = '';
      let error_type = 'PaymentError';

      const stripe = await stripePromise;
      const scanner_continue = searchParams.get('scanner_continue') ? searchParams.get('scanner_continue') : '';
      if (stripe && response.data && response.data.client_secret) {

        stripe.confirmCardPayment(response.data.client_secret).then(result => {
          if (result.error) {
            console.error('Payment error:', result.error);
            error_message = result.error.message || '';
            error_type = result.error.code || '';
            saveFinancingError(error_message, error_type);
          }
          else {
            dispatch(setLoading(false));
            // toastr.showSuccess('Successfully created the contract');
            if (scanner_continue && JSON.parse(scanner_continue)) {
              const obj = {
                doctor_id: ((split_request_data && split_request_data['doctor_id']) ? split_request_data['doctor_id'] : 0),
                contract_code: response.contract_code
              };
              socket.emit('STOP_LOADING', obj)
            }
            dispatch(setContractCode(response.contract_code));
            if (api.includes('paid_full')) {
              setdebtPaymentSuccessSourceType((selectedCards && selectedCards.primary && selectedCards.primary.payment_source_type) || PaymentMethodType.CARD);
            } else {
              navigate('/plan-created/' + token + '?debttype='+debtRecoveryType);
            }
          }

        }).catch(e => {
          console.error('Payment error in catch:', e);
          error_message = e.message || e;
          saveFinancingError(error_message, e.type);
        });

      } else {
        dispatch(setLoading(false));
        // toastr.showSuccess('Successfully created the contract');
        if (scanner_continue && JSON.parse(scanner_continue)) {
          const obj = {
            doctor_id: ((split_request_data && split_request_data['doctor_id']) ? split_request_data['doctor_id'] : 0),
            contract_code: response.contract_code
          };
          socket.emit('STOP_LOADING', obj)
        }
        dispatch(setContractCode(response.contract_code))
        if (api.includes('paid_full')) {
          setdebtPaymentSuccessSourceType((selectedCards && selectedCards.primary && selectedCards.primary.payment_source_type) || PaymentMethodType.CARD);
        } else {
          navigate('/plan-created/' + token + '?debttype='+debtRecoveryType);
        }
      }
    } else {
      dispatch(setLoading(false));
      dispatch(showToaster({ message: response?.original_text, type: ToasterType.Danger, status: true }));
    }
  }

  // const renderTooltip = (props: any) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     It includes Enrollment fee and Transaction fees
  //   </Tooltip>
  // );

  const goBack = async (path: string = '') => {
    if (path && path.length) {
      navigate(path);
    } else {
      navigate("/debt-recovery/" + token);
    }
  }

  const deleteCard = (type: string) => {
    if (type === PaymentMatrixTypeEnum.BACKUP) {
      setSelectedCards((stateData: any) => ({
        ...stateData,
        backup: null,
      }))
    }
  }

  const start_date = new Date(recurring_date).getDate() + getDateSuffix(new Date(recurring_date).getDate()) + " of each month" || "Monthly";

  const patient_name = (patients?.patient_first_name && patients?.patient_last_name ) ? (patients?.patient_first_name + (" ") + patients?.patient_last_name) : 
  (split_request_data?.patient_first_name + (" ") + split_request_data?.patient_last_name);
  return (
    <>
      {!debtPaymentSuccessSourceType && <>
        {!debtPayInFull && (
          <div className="h-100 d-flex flex-column justify-content-between bg-white">
            <div className='h-100 overflow-auto hide-scroll-bar'>
            <div
              className=" pt-md-5 p-3 p-md-4  container "
              style={{ maxWidth: "1000px" }}
            >
              <Row className="mb-3 m-0">
                <Col className="p-0">
                  <h2 className="fs-28 fw-800 heading-text d-none d-sm-flex">
                    How would you like to pay?
                  </h2>
                  <p className="fs-16 fw-400 d-none d-sm-flex">
                    Before proceeding, verify all details and set payment method.
                  </p>
                  <div className="p-3 text-center d-flex flex-column justify-content-between d-sm-none my-3 border-bottom pb-4">
                    {/* <span>
                      <p className="fs-24 fw-700 m-0 text-success mb-2">
                        {doctor_country_currency_info?.currency?.[0]?.display_logo}
                        {selectedPaymentPlan &&
                          selectedPaymentPlan.recurring_amount}{" "}
                        for next{" "}
                        {selectedPaymentPlan && ( (split_request_data?.payment_reserve_on_pay_new_ten === 1 && selectedPaymentPlan.contract_type === 3) ?  (selectedPaymentPlan.month - 1) : selectedPaymentPlan.month)} months
                      </p>
                      <p className="fs-16 fw-500 m-0 mb-3">
                        Total Payable Today :{" "}
                        <b>{doctor_country_currency_info?.currency?.[0]?.display_logo}
                          {selectedPaymentPlan && selectedPaymentPlan.to_pay_today ? formatNumber(selectedPaymentPlan.to_pay_today) + '' : 0}</b>
                      </p>
                    </span>
                    <span className="border-top pt-3">
                      <p className="fs-14 fw-500 m-0">
                        Contract with:{" "}
                        <span> {split_request_data?.doctor_name} </span>
                      </p>
                    </span> */}
                    <p className='m-0 fs-14 fw-600 mb-2'>
                    Total Payable Today
                    </p>
                    <p className="fts-42 fw-800 m-0 mb-2 d-flex align-items-center justify-content-center">
                        {doctor_country_currency_info?.currency?.[0]?.display_logo}
                        {selectedPaymentPlan &&
                          selectedPaymentPlan?.to_pay_today ?
                          formatNumber(selectedPaymentPlan?.to_pay_today) + '' : 0}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={TooltipFn(renderHTML)}
                        >
                          <img
                            className="ms-2 cursor"
                            src="/assets/images/info-circle.svg"
                            style={{ width: "20px" }}
                            alt=""
                          />
                        </OverlayTrigger>
                      </p>
                      <p className="fs-18 fw-700 m-0 mb-3">
                      Monthly Recurring:
                          {' '+ doctor_country_currency_info?.currency?.[0]?.display_logo }
                          {selectedPaymentPlan &&
                            formatNumber(selectedPaymentPlan.recurring_amount)}{" "}
                        </p>
                        <div className="d-flex align-items-center py-1 rounded-pill border fw-600 fs-14 black-65 px-3 mx-auto" style={{width :"fit-content"}}>
                       {debtRecoveryType ? 'First' : ''} Recurring Date:
                      <div className={`position-relative ${!debtRecoveryType ? 'pe-4' : ''}`}>
                      <DatePicker
                              onKeyDown={(e) => e.preventDefault()}
                              className="w-auto"
                              dateFormat="MM/dd/yyyy"
                              ref={datepickerRef}
                              minDate={tomorrow}
                              maxDate={max_recurring_date}
                              selected={recurring_date}
                              onChange={(date) => finalPaymentData(date, "date")}
                              customInput={
                                <input
                                className={`datepickerInput w-100 fw-600 fs-14 black-65 ${debtRecoveryType ? 'pe-none' : ''}`}
                                style={{
                                  height: "100%",
                                  border: "solid 0 #ccc",
                                  paddingLeft: "2px",
                                  cursor: "pointer",
                                  maxWidth: "80px"
                                }}
                              
                                />
                              }
                            />
                          {!debtRecoveryType && (
                              <>
                                <img
                                  className="position-absolute cursor"
                                  style={{ top: "-4px", right: "-6px" }}
                                  alt=""
                                  onClick={() => {
                                    if (datepickerRef.current) {
                                      datepickerRef.current.setFocus();
                                    }
                                  }}
                                  src="\assets\images\edit-pencil.svg"
                                />
                              </>
                            )}
                      </div>
                        </div>
                  </div>
                </Col>
              </Row>

              {selectedPaymentPlan &&
                Number(selectedPaymentPlan?.to_pay_today) > 0 &&
                <>
                  <Row className="mb-sm-3 m-0 align-items-center">
                    <Col className="p-0 ">
                    <p className="fs-14 fw-700 m-0 d-sm-none">
                      {!debtRecoveryType || (debtRecoveryType && getItem('partial_amount')) ? 'Payment Method' : (split_request_data?.charge_first_recuring_for_debt_recovery == 1 ? '1st Recurring Payment Due Today' : 'Payment Method')}
                    </p>
                    <p className="fs-12 fw-500 black-65 m-0 d-sm-none">
                    This payment method will be used for today's payment.
                   </p>
                    <p className="fs-16 fw-600 m-0 d-none d-sm-flex">
                      {!debtRecoveryType || (debtRecoveryType && getItem('partial_amount')) ? 'Total Payable Today' : (split_request_data?.charge_first_recuring_for_debt_recovery == 1 ? '1st Recurring Payment Due Today' : 'Total Payable Today')}
                    </p>
                      <p className="fs-42 fw-800 m-0 d-none d-sm-flex">
                        {doctor_country_currency_info?.currency?.[0]?.display_logo}
                        {selectedPaymentPlan &&
                          selectedPaymentPlan?.to_pay_today ?
                          formatNumber(selectedPaymentPlan?.to_pay_today) + '' : 0}
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={TooltipFn(renderHTML)}
                        >
                          <img
                            className="ms-2 cursor"
                            src="/assets/images/info-circle.svg"
                            style={{ width: "20px" }}
                            alt=""
                          />
                        </OverlayTrigger>
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-end flex-column">
                      {(!selectedCards.primary ||
                        (selectedCards.primary &&
                          selectedCards.primary.last4 === 0)) && (
                          <div className="d-flex align-items-center">
                            {/* {iphoneDevice && (
                              <Button
                                variant="outline"
                                className="btn me-3 fw-800 d-flex align-items-center bg-black text-white"
                              // onClick={() => handleShow()}
                              >
                                <img
                                  src="/assets/images/apple-icon.svg"
                                  className="me-2 hw-20"
                                  alt=""
                                />
                                Pay
                              </Button>
                            )} */}

                            <Button
                              className="bg-white border border-primary text-primary fw-600"
                              onClick={() => handleShowModal(PaymentMatrixTypeEnum.PRIMARY)}
                            >
                             {isSmallScreen ? 'Add' : ' Add Payment Method'}
                            </Button>
                            {/* <Button
                              variant="outline"
                              className="btn me-3 fw-500 d-flex align-items-center bg-primary text-white"
                              onClick={() =>
                                handleShow(
                                  PaymentMatrixTypeEnum.PRIMARY,
                                  PaymentMethodType.CARD
                                )
                              }
                            >
                              <img
                                src="/assets/images/card-icon.svg"
                                className="me-2 hw-20"
                                alt=""
                              />
                              Card
                            </Button>
                            <Button
                              variant="outline"
                              className="btn fw-500 d-flex align-items-center text-primary border-primary"
                              onClick={() =>
                                handleShow(
                                  PaymentMatrixTypeEnum.PRIMARY,
                                  PaymentMethodType.BANK
                                )
                              }
                            >
                              <img
                                src="/assets/images/bank-icon.svg"
                                className="me-2 hw-20"
                                alt=""
                              />
                              Bank
                            </Button> */}
                          </div>
                        )}
                      {selectedCards.primary && selectedCards.primary.last4 !== 0 && (
                        <div className="d-flex p-1 border radius-4 align-items-center">
                          <img
                            alt=""
                            src={
                              "/assets/images/card-type/" +
                              (selectedCards.primary.brand + '').toLowerCase() +
                              ".svg"
                            }
                            className="me-2"
                            style={{ width: "30px", height: "18px" }}
                          />
                          <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                            <span className="me-2">****</span>
                            {selectedCards.primary.last4}
                          </p>
                          <img
                            alt=""
                            src="/assets/images/edit-pencil.svg"
                            className="ms-2 cursor"
                            onClick={() =>
                              handleShow(
                                PaymentMatrixTypeEnum.PRIMARY,
                                PaymentMethodType.CARD
                              )
                            }
                          />
                        </div>
                      )}
                      <p className='fs-12 fw-400 pt-2 mb-0 d-none d-sm-flex' style={{ color: '#5A5E64', textAlign: 'right' }}>This payment method will be used for payment.</p>
                    </Col>
                  </Row>

                  <hr className='d-none d-sm-flex' />
                </>
                }
              {/* Duration content */}
              <Row className="mb-sm-3 m-0">
                <>
                  {(!debtRecoveryType || (debtRecoveryType && getItem('partial_amount'))) ?
                    (<>
                      <Col className="d-none d-sm-flex align-items-center p-0 mb-3">
                      <p className="fs-18 fw-600 m-0">{`Monthly recurring for next ${selectedPaymentPlan && ( (split_request_data?.payment_reserve_on_pay_new_ten === 1 && selectedPaymentPlan.contract_type === 3) ?  (selectedPaymentPlan.month - 1) : selectedPaymentPlan.month)} months `}</p>
                      </Col>
                      <Col className="d-none d-sm-flex justify-content-end position-relative">
                        <p className="fs-18 fw-700 m-0">
                          {doctor_country_currency_info?.currency?.[0]?.display_logo}
                          {selectedPaymentPlan && formatNumber(selectedPaymentPlan.recurring_amount)
                            }{" "}
                        </p>
                      </Col>
                    </>) : (
                      <>
                        <Col className="d-none d-sm-flex align-items-center p-0 mb-3">
                          <p className="fs-18 fw-600 m-0">{'Plan Duration'}</p>
                        </Col>
                        <Col className="d-flex justify-content-end position-relative">
                          <p className="fs-18 fw-700 m-0 d-none d-sm-flex">
                            {selectedPaymentPlan && selectedPaymentPlan.month}{" "} months
                          </p>
                        </Col>
                      </>
                    )
                  }
                </>
              </Row>

              <hr className='d-none d-sm-flex'/>
              {/* Next payment date */}
              <Row className="mb-3 m-0">
                {
                  <>
                    {debtRecoveryType ?
                      (
                        <>
                          <Col className="d-none d-sm-flex align-items-center p-0 mb-3">
                            <p className="fs-18 fw-600 m-0">Next Payment Date</p>
                          </Col>
                          <Col className="fs-18 fw-700 d-none d-sm-flex justify-content-end position-relative">
                            {formatDateNew(recurring_date)}
                          </Col>
                        </>
                      ) :
                      (
                        <>
                          <Col className="d-flex align-items-center p-0 mb-3">
                            <p className="fs-18 fw-600 m-0"><p className="fs-18 fw-500 m-0">{(split_request_data?.payment_reserve_on_pay_new_ten === 1 && selectedPaymentPlan?.contract_type === 3) ? 'Next recurring Date' : 'Recurring Date'} </p></p>
                          </Col>
                          <Col className="d-none d-sm-flex justify-content-end position-relative">
                            <DatePicker
                              onKeyDown={(e) => e.preventDefault()}
                              className="w-auto mxw-130"
                              dateFormat="MM/dd/yyyy"
                              ref={datepickerRef}
                              minDate={tomorrow}
                              maxDate={max_recurring_date}
                              selected={recurring_date}
                              onChange={(date) => finalPaymentData(date, "date")}
                              customInput={
                                <input
                                  className="datepickerInput w-100 fw-600 fs-14"
                                  style={{
                                    height: "100%",
                                    borderRadius: "6px",
                                    border: "solid 1px #ccc",
                                    paddingLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              }
                            />
                            <img
                              className="position-absolute cursor"
                              style={{ top: "3px" }}
                              alt=""
                              onClick={() => {
                                if (datepickerRef.current) {
                                  datepickerRef.current.setFocus();
                                }
                              }} 
                              src="\assets\images\edit-pencil.svg"
                            />
                          </Col>
                        </>
                      )
                    }
                  </>
                }
              </Row>

              <hr />
              {/* recurring payment type */}
              <Row className="mb-3 m-0">
                <Col className="p-0 mb-3">
                  <p className="fs-14 fw-700 m-0  d-sm-none">
                    Recurring Payment Method
                  </p>
                  <p className="fs-14 fw-700 m-0 d-none d-sm-flex">
                    Recurring Payment Method
                  </p>
                  <p className={`m-0 ${isSmallScreen ? 'fs-12' : 'fs-14'}`}>
                    All future recurring payments will be charged to this payment method.
                  </p>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  {(!selectedCards.recurring ||
                    (selectedCards.recurring &&
                      selectedCards.recurring.last4 === 0)) && (
                      <div className="d-flex align-items-center">
                        {/* {iphoneDevice && (
                          <Button
                            variant="outline"
                            className="btn me-3 fw-800 d-flex align-items-center bg-black text-white"
                          >
                            <img
                              src="/assets/images/apple-icon.svg"
                              className="me-2 hw-20"
                              alt=""
                            />
                            Pay
                          </Button>
                        )} */}
                        {/* <Button
                          variant="outline"
                          className="btn me-3 fw-500 d-flex align-items-center bg-primary text-white"
                          onClick={() =>
                            handleShow(
                              PaymentMatrixTypeEnum.RECURRING,
                              PaymentMethodType.CARD
                            )
                          }
                        >
                          <img
                            src="/assets/images/card-icon.svg"
                            className="me-2 hw-20"
                            alt=""
                          />
                          Card
                        </Button>
                        <Button
                          variant="outline"
                          className="btn fw-500 d-flex align-items-center text-primary border-primary"
                          onClick={() =>
                            handleShow(
                              PaymentMatrixTypeEnum.RECURRING,
                              PaymentMethodType.BANK
                            )
                          }
                        >
                          <img
                            src="/assets/images/bank-icon.svg"
                            className="me-2 hw-20"
                            alt=""
                          />
                          Bank
                        </Button> */}
                        <Button
                          className="bg-white border border-primary text-primary fw-600"
                          onClick={() => handleShowModal(PaymentMatrixTypeEnum.RECURRING)}
                        >
                           {isSmallScreen ? 'Add' : ' Add Payment Method'}
                        </Button>
                      </div>
                    )}
                  {selectedCards.recurring &&
                    selectedCards.recurring.last4 !== 0 && (
                      <div className="d-flex p-1 border radius-4 align-items-center">
                        <img
                          alt=""
                          src={
                            "/assets/images/card-type/" +
                            (selectedCards.recurring.brand).toLowerCase() +
                            ".svg"
                          }
                          className="me-2"
                          style={{ width: "30px", height: "18px" }}
                        />
                        <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                          <span className="me-2">****</span>
                          {selectedCards.recurring.last4}
                        </p>
                        <img
                          src="/assets/images/edit-pencil.svg"
                          className="ms-2 cursor"
                          alt=""
                          onClick={() =>
                            handleShow(
                              PaymentMatrixTypeEnum.RECURRING,
                              PaymentMethodType.CARD
                            )
                          }
                        />
                      </div>
                    )}
                </Col>
              </Row>

              <hr />

              {/* backup payment type */}
              <Row className="mb-3 m-0">
                <Col  className="p-0 mb-3">
                  <p className="fs-14 fw-700 m-0">
                    Backup Payment Method <span style={{ color: '#FD8105' }}>(Recommended)</span>
                  </p>
                  <p className={`m-0 ${isSmallScreen ? 'fs-12' : 'fs-14'}`}>
                    Add a backup payment method to avoid missed payments and negatively impacting your credit score.
                  </p>
                </Col>
                <Col  className="d-flex justify-content-end align-items-center">
                  {(!selectedCards.backup ||
                    (selectedCards.backup &&
                      selectedCards.backup.last4 === 0)) && (
                      <div className="d-flex align-items-center">
                        {/* {iphoneDevice && (
                          <Button
                            variant="outline"
                            className="btn me-3 fw-800 d-flex align-items-center bg-black text-white"
                          >
                            <img
                              src="/assets/images/apple-icon.svg"
                              className="me-2 hw-20"
                              alt=""
                            />
                            Pay
                          </Button>
                        )} */}
                        {/* <Button
                          variant="outline"
                          className="btn me-3 fw-500 d-flex align-items-center bg-primary text-white"
                          onClick={() =>
                            handleShow(
                              PaymentMatrixTypeEnum.BACKUP,
                              PaymentMethodType.CARD
                            )
                          }
                        >
                          <img
                            src="/assets/images/card-icon.svg"
                            className="me-2 hw-20"
                            alt=""
                          />
                          Card
                        </Button>
                        <Button
                          variant="outline"
                          className="btn fw-500 d-flex align-items-center text-primary border-primary"
                          onClick={() =>
                            handleShow(
                              PaymentMatrixTypeEnum.BACKUP,
                              PaymentMethodType.BANK
                            )
                          }
                        >
                          <img
                            src="/assets/images/bank-icon.svg"
                            className="me-2 hw-20"
                            alt=""
                          />
                          Bank
                        </Button> */}
                        <Button
                          className="bg-white border border-primary text-primary fw-600"
                          onClick={() => handleShowModal(PaymentMatrixTypeEnum.BACKUP)}
                        >
                           {isSmallScreen ? 'Add' : ' Add Payment Method'}
                        </Button>
                      </div>
                    )}
                  {selectedCards.backup && selectedCards.backup.last4 !== 0 && (
                    <div className="d-flex p-1 border radius-4 align-items-center" style={{maxHeight: '40px'}}>
                      <img
                        alt=""
                        src={
                          "/assets/images/card-type/" +
                          (selectedCards.backup.brand).toLowerCase() +
                          ".svg"
                        }
                        className="me-2"
                        style={{ width: "30px", height: "18px" }}
                      />
                      <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                        <span className="me-2">****</span>
                        {selectedCards.backup.last4}
                      </p>
                      <img
                        src="/assets/images/edit-pencil.svg"
                        className="ms-2 cursor"
                        alt=""
                        onClick={() =>
                          handleShow(
                            PaymentMatrixTypeEnum.BACKUP,
                            PaymentMethodType.CARD
                          )
                        }
                      />

                      <img src="/assets/images/delete.png" className="ms-2 cursor" style={{ width: '20px', height: '20px' }} alt="" onClick={() =>
                        deleteCard(
                          PaymentMatrixTypeEnum.BACKUP
                        )
                      }
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <hr />

                  {/* Signature */}
              <Row className="mb-3 m-0">
                <Col className="d-flex align-itwms-center justify-content-between p-0">
                  <p className="fs-18 fw-600 m-0">Customer Signature</p>
                  <span className="d-flex align-items-center">
                    <label className="switch me-2">
                      <input
                        type="checkbox"
                        id="toggleSwitch"
                        checked={paymentData.generateSignature}
                        onChange={($event) => finalPaymentData($event, "signature")}
                      />
                      <span className="slider"></span>
                    </label>
                    <label className="fs-14 fw-400 m-0">Generate Signature</label>
                  </span>
                </Col>
              </Row>

              {/* Signature */}
              <Row className="mb-3 m-0">
                <Col className="p-0">
                  <div
                    className="signature-panel radius-8 position-relative d-flex align-items-center justify-content-center"
                    style={{ border: "2px solid #ced4da", height: "300px" }}
                  >
                    {paymentData.generateSignature ? (
                      <>
                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                          <p
                            className="fs-60"
                            id="signature-text"
                            style={{
                              fontFamily: selectedFont,
                            }}
                          >
                            {((patients?.patient_first_name || "") + (" ") +
                              (patients?.patient_last_name || "")) || ((split_request_data?.patient_first_name || "") + (" ") +
                                (split_request_data?.patient_last_name || "")) ||
                              ""}
                          </p>
                        </div>

                        <Dropdown
                          className="position-absolute  cursor  rounded-circle hw-40"
                          style={{ right: "25px", bottom: "25px" }}
                        >
                          <Dropdown.Toggle
                            className="bg-transparent border-0 rounded-circle"
                            style={{ minWidth: "unset" }}
                          >
                            <img
                              src="/assets/images/fonts-icon.svg"
                              alt=""
                              id="dropdown-basic"
                              className="position-absolute bottom-0 end-0 cursor"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="decoration-none p-3 radius-8 border bg-white">
                            {fontFamilyList &&
                              fontFamilyList.map((item: any, index: number) => (
                                <Dropdown.Item
                                  key={index}
                                  onMouseEnter={(event: any) => {
                                    // console.log(fontFamilyList.indexOf(event.target['textContent']))
                                    setSelectedFont(event.target["textContent"]);
                                    saveSuggestedSignature();
                                  }}
                                  className={
                                    "d-flex justify-content-between cursor " +
                                    (selectedFont === item ? "active" : "")
                                  }
                                >
                                  {item}
                                </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      <div className="h-100 w-100">
                        <SignatureCanvas
                          ref={(ref) => {
                            sigCanvas = ref;
                          }}
                          penColor="black"
                          canvasProps={{ className: "sigCanvas w-100 h-100" }}
                          onEnd={getSignature}
                        />
                        <img
                          src="/assets/images/eraser-icon.svg"
                          alt=""
                          className="position-absolute cursor"
                          style={{ right: "25px", bottom: "25px" }}
                          onClick={clearSignature}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

                    {/* Terms */}
              <Row className="mb-3 m-0 p-0">
                <Col className="p-0">
                  <Form.Check
                    checked={eSignature}
                    onChange={handleCheckboxChange2}
                    id="eSignatureCheckbox"
                    type="checkbox"
                    label={
                      <>
                        <label htmlFor="eSignatureCheckbox" style={{ cursor: 'pointer' }}>
                          By proceeding, I acknowledge and agree that my electronic signature carries the same legal validity and enforceability as a handwritten signature for this contract.
                        </label>
                      </>
                    }
                  />
                </Col>
              </Row>
              {/* Terms */}
              <Row className="mb-3 m-0 p-0">
                <Col className="p-0">
                  <Form.Check
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    id="agreeterms"
                    label={
                      <>
                        <label htmlFor="agreeterms" style={{ cursor: 'pointer' }}>
                          I've read & agreed to the{" "}
                          {(!debtPayInFull) && (
                            <>
                              <a href={`/tila/${token}/${start_date}`} target='_blank' rel='noreferrer'>TILA Disclosure</a>,
                            </>
                          )}
                          {" "}
                          <a href={`/hipaa/${token}`} target='_blank' rel='noreferrer'>Payment Authorization Agreement{" "}
                            and HIPAA Disclosure</a>.
                        </label>
                      </>
                    }
                  />
                </Col>
              </Row>

              {debtRecoveryType && <Row className="mb-3 m-0">
                <div className=" d-flex justify-content-between align-items-center payment-footer w-100 ps-0 pe-0 py-3 -border-top">
                  <button style={{ padding: '12px 23px' }} className='d-flex justify-content-center align-items-center btn btn-outline mt-0' onClick={() => goBack(getItem('pay_in_full') ? ('/debt-recovery/' + token) : ('/debt-recovery/customer-details/' + token))}>
                    <img className=" cursor me-3" src="/assets/images/left-arrow.svg" alt="" />
                    Back
                  </button>
                  {/* <img
                        className="cursor"
                        src="/assets/images/back-circle.svg"
                        alt=""
                        onClick={() => goBack(getItem('pay_in_full') ? ('/debt-recovery/' + token) : ('/debt-recovery/customer-details/' + token))}
                      /> */}
                  <button
                    onClick={() => createContract()}
                    className="btn btn-secondary fs-18 fw-700"
                  >
                    Confirm & Pay {" "}
                    {doctor_country_currency_info?.currency?.[0]?.display_logo}
                    {formatNumber(Number(selectedPaymentPlan?.to_pay_today))}
                  </button>
                </div>
              </Row>}
            </div>
            </div>
            {!debtRecoveryType && <div className="w-100 p-4 py-3 border-top">
              <div className=" d-flex justify-content-between align-items-center payment-footer w-100 container-xl">

              <img
                className="cursor"
                src="/assets/images/back-circle.svg"
                alt=""
                onClick={() => changeStep()}
              />
              <button
                onClick={() => createContract()}
                className="btn btn-secondary fs-18 fw-700"
              >
                Confirm & Pay {doctor_country_currency_info?.currency?.[0]?.display_logo}
                {selectedPaymentPlan &&
                  selectedPaymentPlan?.to_pay_today ?
                  (formatNumber(selectedPaymentPlan?.to_pay_today)) + '' : ''}
              </button>
              </div>
            </div>}
          </div>
        )}

        {/* if debt pay in full flow */}
        {debtPayInFull && (
          <>
            <div className="bg-white h-sm-100">
              <div className={`payment-wrapper mobile-padding d-flex flex-column justify-content-between mxw-451 h-100`}>
                <div className="h-100 overflow-sm-auto pt-sm-5 hide-scroll-bar mxw-600 mx-auto ">
                <div className="text-center d-sm-none pb-4 border-bottom mb-4 mb-sm-5">
                      <img src="/assets/images/pay-recipt.png"  className='mb-3' />
                      <p className="fs-14 fw-500 grey-65 mb-2">
                        Total Amount Due | Pending For{" "}
                        {split_request_data?.debt_duration}
                      </p>
                      <h4 className="fs-42 fw-800 m-0">
                        {
                          doctor_country_currency_info?.currency?.[0]
                            ?.display_logo
                        }
                        {formatNumber(to_pay_today) + ""}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={TooltipFn("Includes Transaction Fees.")}
                        >
                          <img
                            className="ms-2 cursor"
                            src="/assets/images/info-circle.svg"
                            style={{ width: "20px" }}
                            alt=""
                          />
                        </OverlayTrigger>
                      </h4>
                    </div>
                  <div className="p-0 d-none d-sm-block">
                    <h2 className="fs-24 fw-800">Settle your debt in full</h2>
                    <p className=" fw-400 fs-16 grey-106">
                      Before proceeding, verify all details and set payment method.
                    </p>
                  </div>

                  <div className="">
                    <p className="fs-14 fw-500 grey-65 d-none d-sm-block">
                      Total Amount Due | Pending For {split_request_data?.debt_duration}
                    </p>
                    <div className="d-flex align-items-center justify-content-between mb-3 mb-sm-0">
                      <p className='d-sm-none fs-16 fw-600 black-65 m-0'>
                      Payment Method
                      </p>
                      <h4 className="fs-42 fw-800 m-0 d-none d-sm-block">
                        {doctor_country_currency_info?.currency?.[0]?.display_logo}
                        {formatNumber(to_pay_today) + ''}
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={TooltipFn('Includes Transaction Fees.')}
                        >
                          <img
                            className="ms-2 cursor"
                            src="/assets/images/info-circle.svg"
                            style={{ width: "20px" }}
                            alt=""
                          />
                        </OverlayTrigger>
                      </h4>

                      {(!selectedCards.primary ||
                        (selectedCards.primary &&
                          selectedCards.primary.last4 === 0)) && (
                          <>
                            <Button
                              className="bg-white border border-primary text-primary fw-600"
                              onClick={() => handleShowModal(PaymentMatrixTypeEnum.PRIMARY)}
                            >
                               {isSmallScreen ? 'Add' : ' Add Payment Method'}
                            </Button>
                          </>
                        )}
                      {selectedCards.primary &&
                        selectedCards.primary.last4 !== 0 && (
                          <div className="">
                            <div className="d-flex border px-2 radius-4">
                              <img alt="" src={"/assets/images/card-type/" + (selectedCards.primary.brand).toLowerCase() + ".svg"}
                                className="me-2"
                                style={{ width: "30px", height: "30px" }}
                              />
                              <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                                <span className="me-2">****</span>
                                {selectedCards.primary.last4}
                              </p>
                              <span> {" "}
                                <img alt="" src="/assets/images/edit-pencil.svg" className="ms-2 cursor" onClick={() =>
                                  handleShow(
                                    PaymentMatrixTypeEnum.PRIMARY,
                                    PaymentMethodType.CARD
                                  )
                                }
                                />
                              </span>
                            </div>
                          </div>
                        )}

                    </div>
                    <p className="fs-12  fw-400 text-muted text-end mt-2 mb-3 d-none d-sm-block">
                      This payment method will be used for payment.
                    </p>
                  </div>
                  <hr className="" />
                  <Row className="mxw-600 mx-auto mb-3 m-0">
                    <Col className="d-flex align-itwms-center justify-content-between p-0">
                      <p className="fs-18 fw-600 m-0">Customer Signature</p>
                      <span className="d-flex align-items-center">
                        <label className="switch me-2">
                          <input
                            type="checkbox"
                            id="toggleSwitch"
                            checked={paymentData.generateSignature}
                            onChange={($event) => finalPaymentData($event, "signature")}
                          />
                          <span className="slider"></span>
                        </label>
                        <label className="fs-14 fw-400 m-0">Generate Signature</label>
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3 m-0">
                    <Col className="p-0">
                      <div
                        className="signature-panel radius-8 position-relative d-flex align-items-center justify-content-center"
                        style={{ border: "2px solid #ced4da", height: "300px" }}
                      >
                        {paymentData.generateSignature ? (
                          <>
                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                              <p
                                className="fs-60"
                                id="signature-text"
                                style={{
                                  fontFamily: selectedFont,
                                }}
                              >
                                {patient_name}
                              </p>
                            </div>

                            <Dropdown
                              className="position-absolute  cursor  rounded-circle hw-40"
                              style={{ right: "25px", bottom: "25px" }}
                            >
                              <Dropdown.Toggle
                                className="bg-transparent border-0 rounded-circle"
                                style={{ minWidth: "unset" }}
                              >
                                <img
                                  src="/assets/images/fonts-icon.svg"
                                  alt=""
                                  id="dropdown-basic"
                                  className="position-absolute bottom-0 end-0 cursor"
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="decoration-none p-3 radius-8 border bg-white">
                                {fontFamilyList &&
                                  fontFamilyList.map((item: any, index: number) => (
                                    <Dropdown.Item
                                      key={index}
                                      onMouseEnter={(event: any) => {
                                        // console.log(fontFamilyList.indexOf(event.target['textContent']))
                                        setSelectedFont(event.target["textContent"]);
                                        saveSuggestedSignature();
                                      }}
                                      className={
                                        "d-flex justify-content-between cursor " +
                                        (selectedFont === item ? "active" : "")
                                      }
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        ) : (
                          <div className="h-100 w-100">
                            <SignatureCanvas
                              ref={(ref) => {
                                sigCanvas = ref;
                              }}
                              penColor="black"
                              canvasProps={{ className: "sigCanvas w-100 h-100" }}
                              onEnd={getSignature}
                            />
                            <img
                              src="/assets/images/eraser-icon.svg"
                              alt=""
                              className="position-absolute cursor"
                              style={{ right: "25px", bottom: "25px" }}
                              onClick={clearSignature}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3 m-0 p-0">
                    <Col className="p-0">
                      <Form.Check
                        checked={eSignature}
                        onChange={handleCheckboxChange2}
                        type="checkbox"
                        id='eSignatureCheckbox'
                        label={
                          <>
                            <label htmlFor="eSignatureCheckbox" style={{ cursor: 'pointer' }}>
                              By proceeding, I acknowledge and agree that my electronic signature carries the same legal validity and enforceability as a handwritten signature for this contract.
                            </label>
                          </>
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mxw-600 mx-auto mb-3 m-0">
                    <Col className="p-0">
                      <Form.Check
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        type="checkbox"
                        id="agreeterms"
                        label={
                          <>
                            <label htmlFor="agreeterms" style={{ cursor: 'pointer' }}>
                              I've read & agreed to the{" "}
                              {!debtPayInFull && (
                                <>
                                  <a href={`/tila/${token}`} target='_blank' rel='noreferrer'>TILA Disclosure</a> ,
                                </>
                              )}{" "}
                              <a href={`/hipaa/${token}`} target='_blank' rel='noreferrer'>Payment Authorization Agreement{" "} and HIPAA Disclosure</a>.
                            </label>
                          </>
                        }
                      />
                    </Col>
                  </Row>

                  {debtRecoveryType && <Row className="mxw-600 mx-auto mb-3 m-0">
                    <div className=" d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center payment-footer w-100 p-4 py-3 px-0 mt-3">
                      <button style={{ padding: '12px 23px' }} className='d-flex justify-content-center align-items-center btn btn-outline w-100 w-sm-auto' onClick={() => goBack(getItem('pay_in_full') ? ('/debt-recovery/' + token) : ('/debt-recovery/customer-details/' + token))}>
                        <img className=" cursor me-3" src="/assets/images/left-arrow.svg" alt="" />
                        Back
                      </button>
                      {/* <img
                        className="cursor"
                        src="/assets/images/back-circle.svg"
                        alt=""
                        onClick={() => goBack(getItem('pay_in_full') ? ('/debt-recovery/' + token) : ('/debt-recovery/customer-details/' + token))}
                      /> */}
                      <button
                        onClick={() => paidFull()}
                        className="btn btn-secondary fs-18 fw-700 mb-3 mb-sm-0 w-100 w-sm-auto"
                      >
                        Confirm & Pay {" "}
                        {doctor_country_currency_info?.currency?.[0]?.display_logo}
                        {formatNumber(to_pay_today)}
                      </button>
                    </div>
                  </Row>}
                </div>
              </div>
              {!debtRecoveryType &&
                <div className='debt-footer debtPayInFull'>
                  <ContinueFooter
                    back={() => goBack(getItem('pay_in_full') ? ('/debt-recovery/' + token) : ('/debt-recovery/customer-details/' + token))}
                    value="Confirm"
                    onContinue={paidFull}
                  />
                </div>
              }
             <div>
             {debtRecoveryType && <CopyRightFooter />}
             </div>
            </div>

          </>
        )}

        <Modal centered show={show} onHide={handleCloseModal}>
          <Modal.Header className='border-0 px-4' closeButton>
            <Modal.Title className="fs-18 fw-700">
              How would you like to pay?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='px-4 '>
            <div className="shadow-box p-3 radius-12 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <div className="me-3 border py-2 px-3 radius-8">
                  <img
                    src="/assets/images/bank-icon.svg"
                    className="hw-24"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="fs-16 fw-600 m-0">Bank Transfer</h4>
                  <p className="fs-12 fw-500 m-0 text-italic text-success">
                    {/* 2% transaction fees */}
                  </p>
                </div>
              </div>
              <p className="fs-16 fw-500 m-0 text-primary cursor" onClick={() =>
                handleShow(
                  payment_matrix_type,
                  PaymentMethodType.BANK
                )
              }>Add</p>
            </div>
            <div className="shadow-box p-3 radius-12 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <div className="me-3 border py-2 px-3 radius-8">
                  <img
                    src="/assets/images/credit-card-icon.svg"
                    className="hw-24"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="fs-16 fw-600 m-0">Credit/Debit Card</h4>
                  <p className="fs-12 fw-500 m-0 text-italic text-warning">
                    {/* 3% transaction fees */}
                  </p>
                </div>
              </div>
              <p className="fs-16 fw-500 m-0 text-primary cursor" onClick={() =>
                handleShow(
                  payment_matrix_type,
                  PaymentMethodType.CARD
                )
              }>Add</p>
            </div>
          </Modal.Body>

          <Modal.Footer className='px-4 fs-14 grey-106'>
            <div className='fw-700 w-100'>Note:</div>
            <p className='fw-500 mt-0'>You will be charged a dollar or 1% (whichever is higher) as an extra transaction fee if you use a non-US debit/credit card.</p>
          </Modal.Footer>
        </Modal>

        {showConfirmationModal && (
          <ConfirmationModal
            isActive={showConfirmationModal}
            onHide={(params: any) => checkRecurringPrimarySame(params)}
            paymentParams={selectedCards.primary}
          />
        )}

        {showAddNewCardModal && (
          <Wrapper
            isActive={showAddNewCardModal}
            onHide={(params) => hide(params)}
            paymentParams={paymentParams}
            patient_details={patient_details}
          />
        )}

        {showExistingCardModal && (
          <ExistingPaymentSources
            isActive={showExistingCardModal}
            onHide={(type: string, data: any) => existingModalHide(type, data)}
            patient_name={
              patients
                ? patients.patient_first_name + " " + patients.patient_first_name
                : ""
            }
            patient_sources={paymentMethods || []}
          />
        )}
      </>}
      {debtPaymentSuccessSourceType != 0 &&
        <>
          <PaymentSuccessfull payment_source_type={debtPaymentSuccessSourceType} />
        </>}
    </>
  );
};

export default PayNowComponent;
